@if (isLoading) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}
@if (!isLoading) {
  <ion-content #page>
    <ion-row style="position: relative; min-height: 100%">
      <ion-col style="margin-bottom: 80px" size="12" size-lg="10" offset-lg="1" size-xl="10" offset-xl="1">
        <app-header [title]="title"></app-header>
        <div class="sticky">
          <a (click)="backToRecord()" mat-button color="accent" style="font-size: 18px; color:#FD3F92;">
            <mat-icon>arrow_left</mat-icon>{{ ("backToRecord" | translate | capitalizeFirstLetter) }} {{ recordNumber }}
          </a>
          <h1 style="text-align: center;">
            {{ observation.signature ?? "anonyme" }}
          </h1>
          <app-square-toc [isModal]="true" [content]="page" [id]="2" [filteredCats]="filteredCats">
          </app-square-toc>
        </div>
        <ion-row>
          <ion-col size="12" size-lg="10" offset-lg="1" size-xl="10" offset-xl="1">
            <div class="sticky">
              <div style="text-align: center">
                <button mat-flat-button color="primary" (click)="delete()" style="border-radius: 0px; margin: 8px">
                  @if (deleteObsLoading) {
                    <mat-progress-spinner style="display: block; margin-left: auto; margin-right: auto;"
                      [diameter]="24" [mode]="'indeterminate'" color="accent">
                    </mat-progress-spinner>
                  }
                  @if (!deleteObsLoading) {
                    <span>{{ "deleteObs" | translate | capitalizeFirstLetter}}</span>
                  }
                  @if (!deleteObsLoading) {
                    <mat-icon>delete</mat-icon>
                  }
                </button>
                <button mat-flat-button color="primary" (click)="backToRecord()">
                  {{ "resumeLater" | translate | capitalizeFirstLetter}}
                </button>
                <button [disabled]="!isFilled" mat-flat-button color="accent" (click)="onConfirmFinish()"
                  style="border-radius: 0px; margin: 8px">
                  {{ isFilled ? ("finish"|translate): ("pleaseReply"|translate)}}
                </button>
                <br />
                @if (!isSaving) {
                  <ion-label>
                    <i>{{ "modificationsSaved" | translate | capitalizeFirstLetter}}</i>
                  </ion-label>
                }
                @if (isSaving) {
                  <ion-label>
                    <i>{{ "saving" | translate | capitalizeFirstLetter}}</i>
                  </ion-label>
                }
                <app-pdf [answers]="answers" [filteredQues]="filteredQues" [isDraft]="true" isObs="true"
                  [min]="observation.minAge | age | async" [max]="observation.maxAge | age | async"
                [signature]="observation.signature" [dateObs]="observation.date" [obsId]="observation.id"></app-pdf>
              </div>
              <mat-divider style="padding: 5px"> </mat-divider>
              <app-filtres [answers]="answers" [obsId]="observation.id" [ageAtObs]="age" [min]="observation.minAge"
                [max]="observation.maxAge" [isObsViewMode]="false" (formFilledEvent)="isFilled = $event"
              (filteredQuesEvent)="filteredQues = $event"></app-filtres>
            </div>
            <app-table [filteredQues]="filteredQues" [isDraft]="true" [isDetailView]="false" [observation]="observation"
              (isSaving)="isSaving = $event" (answersChanged)="answers = $event">
            </app-table>
          </ion-col>
        </ion-row>
      </ion-col>
      <app-footer></app-footer>
    </ion-row>
  </ion-content>
}