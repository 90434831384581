import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import pros from 'src/assets/json/pros.json';
@Component({
    selector: 'app-pros',
    templateUrl: './pros.component.html',
    styleUrls: ['./pros.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProsComponent implements OnInit {
  period: string;
  pros = pros;
  constructor() {}

  ngOnInit(): void {
    this.period = '1';
  }
}
