<div class="sticky" style="
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  ">
  <div style="
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    ">
    <!-- Counter -->
    <div style="display: inline-block; position: relative">
      @if (!isObsViewMode) {
        <mat-chip style="background-color: transparent;" [selectable]="false">
          {{ "questionsAnswersCount" | translate:{answerCounter: answerCounter, questionCounter:questionCounter, noReply:
          questionCounter- answerCounter} | capitalizeFirstLetter}}
        </mat-chip>
      }
      @if (isObsViewMode) {
        <mat-chip style="background-color: transparent;" [selectable]="false">
          {{ "questionsCount" | translate:{questionCounter:questionCounter} | capitalizeFirstLetter}}
        </mat-chip>
      }
      <div style="
          position: absolute;
          width: 350px;
          box-sizing: border-box;
          left: 0px;
          top: 40px;
          z-index: 12;
        ">
      </div>
    </div>
  </div>
  <div style="
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex;
">
    <mat-chip-set>
      <!-- Age -->
      <div style="display: inline-block; position: relative">
        @if (!isObsViewMode) {
          <mat-chip [ngClass]="{ selected: isAgeChanged }" selectable="false"
            style="cursor: pointer" (click)="onFilter()">{{ min | age | async }} {{ max | age | async }}
            @if (isUpdateMinMaxLoading) {
              <mat-progress-spinner style="display: block; margin-left: auto; margin-right: auto;"
                [diameter]="24" [mode]="'indeterminate'" color="accent">
              </mat-progress-spinner>
            }
            @if (isAgeChanged) {
<mat-icon [ngClass]="{
              selected: isAgeChanged
            }" matChipRemove (click)="resetSelectedAge()">cancel</mat-icon>
            }
          </mat-chip>
        }
        @if (isObsViewMode) {
          <p style="margin: 0; font-weight: 500;">{{ 'observedPeriod' | translate |
          capitalizeFirstLetter }}: {{ min | age | async }} - {{ max | age | async }}</p>
        }
      </div>
      <!-- Domaines -->
      <div style="display: inline-block; position: relative; margin-left: 8px;">
        <mat-chip style="cursor: pointer;"
          (click)="openDomainFiltrePanel()">
          {{ selectedCatTitle | translate | capitalizeFirstLetter }}
          <mat-icon matChipAvatar>arrow_drop_down</mat-icon>
        </mat-chip>
        <div style="
            position: absolute;
            width: fit-content;
            box-sizing: border-box;
            right: 0px;
            top: 40px;
            z-index: 12;
          ">
          <mat-expansion-panel style="min-width: 240px;" [expanded]="catFilterPanelState" (opened)="catFilterPanelState = true"
            (closed)="catFilterPanelState = false">
            <ion-row>
              <ion-col size="10">
                <div style="text-align: left">
                  <h2>{{ "domains" | translate | capitalizeFirstLetter}}</h2>
                </div>
              </ion-col>
              <ion-col size="2">
                <div style="text-align: right">
                  <button (click)="openDomainFiltrePanel()" mat-icon-button
                    aria-label="Example icon button with a vertical three dot icon">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </ion-col>
            </ion-row>

            <div>
              @for (c of catChoices; track c; let i = $index) {
                <div>
                  <mat-checkbox [checked]="selectedCats.includes(i)" class="d-block"
                    (change)="onDomainChange(i, $event.checked)">
                    {{ c.label | translate | capitalizeFirstLetter}}
                  </mat-checkbox>
                </div>
              }
              <br />
            </div>
          </mat-expansion-panel>
        </div>
      </div>

      <!-- Questions -->
      <div style="display: inline-block; position: relative">
        <mat-chip [ngClass]="{
            red: questionFilter === 'red',
            selected: questionFilter !== 'all' && questionFilter !== 'red'
          }" style="cursor: pointer" (click)="openTypeFiltrePanel()">
          {{ selectedTypeTitle | translate }}
          @if (questionFilter === 'all') {
            <mat-icon trailingIcon matChipAvatar >arrow_drop_down</mat-icon>
          }
          @if (questionFilter !== 'all') {
<mat-icon [ngClass]="{
              red: questionFilter === 'red',
              selected: questionFilter !== 'all' && questionFilter !== 'red'
            }" matChipRemove (click)="resetSelectedType()">cancel</mat-icon>
          }
        </mat-chip>
        <div style="
            position: absolute;
            width: fit-content;
            box-sizing: border-box;
            right: 0px;
            top: 40px;
            z-index: 12;
          ">
          <mat-expansion-panel style="min-width: 320px;" [expanded]="typeFiltrePanelState" (opened)="typeFiltrePanelState = true"
            (closed)="typeFiltrePanelState = false">
            <div style="text-align: right">
              <ion-row>
                <ion-col size="10">
                  <div style="text-align: left">
                    <h2>{{"questions" | translate | capitalizeFirstLetter}}</h2>
                  </div>
                </ion-col>
                <ion-col size="2">
                  <div style="text-align: right">
                    <button (click)="openTypeFiltrePanel()" mat-icon-button
                      aria-label="Example icon button with a vertical three dot icon">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </ion-col>
              </ion-row>
            </div>
            <mat-radio-group [(ngModel)]="questionFilter" (ngModelChange)="onTypeChange($event)">
              <mat-radio-button style="margin: 2px" value="all">
                {{"displayAll" | translate | capitalizeFirstLetter}}
              </mat-radio-button>
              <br />
              <mat-radio-button style="margin: 2px" value="starting">
                {{"learningStart" | translate | capitalizeFirstLetter}}
              </mat-radio-button>
              <br />
              <mat-radio-button style="margin: 2px" value="until">
                {{"learningEnd" | translate | capitalizeFirstLetter}}
              </mat-radio-button>
              <br />
              <mat-radio-button style="margin: 2px" value="red">
                {{"learningLate" | translate | capitalizeFirstLetter}}
              </mat-radio-button>
              <mat-radio-button style="margin: 2px" value="green">
                {{"learningEarly" | translate | capitalizeFirstLetter}}
              </mat-radio-button>
              <br />
            </mat-radio-group>
          </mat-expansion-panel>
        </div>
      </div>

      <!-- Réponses -->
      <div style="display: inline-block; position: relative">
        <mat-chip [ngClass]="{
            selected: isObsViewMode ? (learningLevelFilter.length > 0) : answerFilter !== 'all'
          }" style="cursor: pointer" (click)="openRepFiltrePanel()">
          {{ selectedQuesRepTitle | translate }}
          @if (isObsViewMode ? (learningLevelFilter.length == 0) : answerFilter === 'all') {
            <mat-icon matChipAvatar>arrow_drop_down
            </mat-icon>
          }
          @if (isObsViewMode ? (learningLevelFilter.length > 0) : answerFilter !== 'all') {
<mat-icon [ngClass]="{
              selected: isObsViewMode ? (learningLevelFilter.length > 0) : answerFilter !== 'all'
            }" matChipRemove (click)="resetReponses()"
            >cancel</mat-icon>
          }
        </mat-chip>
        <div style="
            position: absolute;
            width: fit-content;
            box-sizing: border-box;
            right: 0px;
            top: 40px;
            z-index: 12;
          ">
          <mat-expansion-panel style="min-width: 240px;" [expanded]="repFiltrePanelState" (opened)="repFiltrePanelState = true"
            (closed)="repFiltrePanelState = false">
            <div style="text-align: right; min-width: 240px;">
              <ion-row>
                <ion-col size="9">
                  <div style="text-align: left">
                    <h2>{{"answers" | translate | capitalizeFirstLetter}}</h2>
                  </div>
                </ion-col>
                <ion-col size="3">
                  <div style="text-align: right">
                    <button (click)="openRepFiltrePanel()" mat-icon-button
                      aria-label="Example icon button with a vertical three dot icon">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </ion-col>
              </ion-row>
            </div>
            <div>
              <mat-radio-group [(ngModel)]="answerFilter" (ngModelChange)="filterQuestions()">
                @if (!isObsViewMode) {
                  <mat-radio-button style="margin: 2px" value="all">
                    {{"displayAll" | translate | capitalizeFirstLetter}}
                  </mat-radio-button>
                }
                <br />
                @if (!isObsViewMode) {
                  <mat-radio-button style="margin: 2px" value="noRep">
                    {{"withoutAnswer" | translate | capitalizeFirstLetter}}
                  </mat-radio-button>
                }
                <br />
                @if (!isObsViewMode) {
                  <mat-radio-button style="margin: 2px" value="rep">
                    {{"learningStaging" | translate | capitalizeFirstLetter}}
                  </mat-radio-button>
                }
                @for (level of levelFilters; track level; let i = $index) {
                  <li style="margin-left: 10%; list-style: none">
                    <mat-checkbox [name]="level.name" [value]="level.value" [checked]="learningLevelFilter.includes(5-i)"
                      [color]="level.color" (change)="filtrePoints($event)">
                      {{ level.name | translate | capitalizeFirstLetter }}
                    </mat-checkbox>
                  </li>
                }
              </mat-radio-group>

              <br />
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </mat-chip-set>
  </div>
</div>
<br>