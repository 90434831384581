import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { finalize, map, take } from 'rxjs/operators';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { obsRep } from 'src/app/shared/models/obsRep.model';

@Component({
    templateUrl: './question-history.component.html',
    styleUrls: ['./question-history.component.scss'],
    standalone: false
})
export class QuestionHistoryComponent implements OnInit {
  history = [];

  dataSource: MatTableDataSource<obsRep>;

  isLoading = true;

  cat: String;

  columnsToDisplay = [
    'Date',
    'Utilisateur',
    'Réflexion',
    'Non-accès',
    "S'exerce",
    'Intègre',
    'Assimilé',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _graphql: GraphqlService,
  ) { }

  async ngOnInit(): Promise<void> {

    this.cat = this.data.answerCode.charAt(0);

    this._graphql.getAnswersHistory(this.data.recordId, this.data.answerCode)
      .pipe(
        take(1),
        map(({ data }: any) => data.answersHistory.map(answer => ({
          date: answer.date,
          level: answer.level,
          signature: answer.observation.signature,
        }
        )
        )
        ),
        finalize(() => this.isLoading = false)
      )
      .subscribe(observations => {
        this.history = observations
        this.dataSource = new MatTableDataSource(this.history);

      })

  }
}
