import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'app-basic-bar',
    templateUrl: './basic-bar.component.html',
    styleUrls: ['./basic-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BasicBarComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts; // required

  chartOptions: Highcharts.Options;

  @Input() chartValues: number[][];

  chartRef: Highcharts.Chart;
  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chartRef = chart;
  };

  @Input() title: string;
  @Input() categories: string[];
  @Input() subCatParams = {}
  

  @Input() colors = [
    '#EEAA3C',
    '#86BD4A',
    '#E26E3E',
    '#58B8DA',
    '#B891B5'
  ]
  constructor(private _translate: TranslateService) { }

  refreshChart(){
    
    this.chartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: this._translate.instant("barChartTitle"),
      },
      subtitle: {
        text: this._translate.instant(this.title, this.subCatParams),
      },
      xAxis: {
        categories: [
          this._translate.instant('unconcerned'),
          this._translate.instant('nonAccess'),
          this._translate.instant("exercising"),
          this._translate.instant('integrating'),
          this._translate.instant('assimilated'),
        ],
      },
      yAxis: {
        min: 0,
        allowDecimals: false,
        title: {
          text: this._translate.instant("answers"),
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
        stackLabels: {
          enabled: true,
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'bottom',
        x: 8,
        y: -16,
        floating: false,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 0,
        shadow: false,
        squareSymbol: false,
        symbolHeight: 12,
        symbolWidth: 12,
        symbolRadius: 0,
        itemMarginTop: 4,
        itemMarginBottom: 4,
        width: 160,
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}',
      },
      series: this.categories.map((cat, index) => (
        {
          name: this._translate.instant(cat),
          type: 'column',
          data: this.chartValues[index],
          color: this.colors[index] || '#000000',
        }
      )),
      credits: {
        enabled: false,
      },
    };
    this.chartRef?.redraw();
  }


  ngOnInit(): void {


    this.chartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: this._translate.instant("barChartTitle"),
      },
      subtitle: {
        text: this._translate.instant(this.title, this.subCatParams),
      },
      xAxis: {
        categories: [
          this._translate.instant('unconcerned'),
          this._translate.instant('nonAccess'),
          this._translate.instant("exercising"),
          this._translate.instant('integrating'),
          this._translate.instant('assimilated'),
        ],
      },
      yAxis: {
        min: 0,
        allowDecimals: false,
        title: {
          text: this._translate.instant("answers"),
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
        stackLabels: {
          enabled: true,
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'bottom',
        x: 8,
        y: -16,
        floating: false,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 0,
        shadow: false,
        squareSymbol: false,
        symbolHeight: 12,
        symbolWidth: 12,
        symbolRadius: 0,
        itemMarginTop: 4,
        itemMarginBottom: 4,
        width: 160,
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}',
      },
      series: this.categories.map((cat, index) => (
        {
          name: this._translate.instant(cat),
          type: 'column',
          data: this.chartValues[index],
          color: this.colors[index] || '#000000',
        }
      )),
      credits: {
        enabled: false,
      },
    };

    this._translate.onLangChange.subscribe(val=>this.refreshChart())

  }
}
