<div style="padding: 20px 24px;">
  <ion-row class="sticky">
    <ion-col size="2"></ion-col>

    <ion-col size="8">
      <h1 style="text-align: center; font-size: 26px;margin-top: 36px;">{{ (data.isArchived ? "archiveSearch" :
        "recordSearch") |
        translate |
      capitalizeFirstLetter }}</h1>
    </ion-col>
    <ion-col style="text-align: end" size="2">
      <ion-button mat-dialog-close fill="clear">
        <mat-icon>close</mat-icon>
      </ion-button>
    </ion-col>
  </ion-row>

  <mat-dialog-content>
    <ion-row>
      <ion-col size-md="8" offset-md="2">
        <ion-row style="place-content: center">
          <ion-col size="6" style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          ">
            <mat-form-field appearance="outline" color="accent" style="width: 240px">
              <mat-label>{{ "recordNumber" | translate | capitalizeFirstLetter }}</mat-label>
              <input autocomplete="off" (keyup)="findRecords($event)" matInput type="text" [(ngModel)]="searchValue" />
              @if (searchValue) {
                <button mat-button matSuffix mat-icon-button aria-label="Clear"
                  (click)="clearSearch()">
                  <mat-icon>close</mat-icon>
                </button>
              }
            </mat-form-field>

          </ion-col>
        </ion-row>
        @if (isLoading) {
          <ion-progress-bar type="indeterminate"></ion-progress-bar>
        }
        <ion-row class="ion-justify-content-center">
          <ion-col size="6" style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          ">
            <div style="width: 240px">
              @if (results && !data.isArchived) {
                <mat-selection-list [multiple]="false" hideSingleSelectionIndicator>
                  @for (record of results; track record) {
                    <mat-list-option mat-dialog-close [routerLink]="['/', 'start','doc', record.id]"
                      [queryParams]="{etabId: data.etabId}">
                      <mat-icon matListItemIcon style="color: black;">folder</mat-icon>
                      <div matListItemTitle>{{ "record" | translate | capitalizeFirstLetter }} {{ record.recordNumber }}</div>
                    </mat-list-option>
                  }
                </mat-selection-list>
              }

              @if (results && data.isArchived) {
                <mat-list [multiple]="false">
                  @for (record of results; track record) {
                    <mat-list-item>
                      <button matListItemMeta mat-flat-button color="accent" (click)="unarchive(record.id)" style="color: white; font-size: 14px;">
                        @if (!unarchiveLoading) {
                          <span>{{ "unarchive" | translate | capitalizeFirstLetter }}</span>
                        }
                        @if (unarchiveLoading) {
                          <mat-progress-spinner style="display: block; margin-left: auto; margin-right: auto;"
                            [diameter]="24" [mode]="'indeterminate'" color="primary">
                          </mat-progress-spinner>
                        }
                      </button>
                      <div matListItemTitle>{{ "record" | translate | capitalizeFirstLetter }} {{ record.recordNumber }}</div>
                    </mat-list-item>
                  }
                </mat-list>
              }
              @if (results?.length === 0) {
                <div style="text-align: center">
                  <h2>{{ "noResultFound" | translate | capitalizeFirstLetter }}</h2>
                </div>
              }
            </div>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </mat-dialog-content>
</div>