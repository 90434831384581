import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { finalize, take } from 'rxjs/operators';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { Subscription } from 'src/app/shared/models/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
@Component({
    templateUrl: './cancel-sub.component.html',
    standalone: false
})
export class CancelSubComponent implements OnInit {
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { subscription: Subscription },
    private dialogRef: MatDialogRef<CancelSubComponent>,
    private _translate: TranslateService,
    private sharedService: SharedService,
    private _graphql: GraphqlService,
  ) { }

  ngOnInit(): void {
  }

  onConfirm() {
    this.isLoading = true;
    this._graphql.toggleCancelSubscription(this.data.subscription.subscriptionId, !this.data.subscription.cancelAtPeriodEnd)
      .pipe(
        take(1),
        finalize(() => this.isLoading = false))
      .subscribe(
        {
          next: () => {
            this.sharedService.makeToast(
              this._translate.instant(!this.data.subscription.cancelAtPeriodEnd ? 'cancelSubConfirmed' : 'restoreCanceledSubConfirmed'),
              '',
              'success'
            );
            this.dialogRef.close();
          },
          error: (error) => {
            this.sharedService.makeToast(
              'error: ' + error,
              '',
              'danger'
            );
            this.dialogRef.close();

          }
        }
      )
  }
}
