import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize, map, take, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/core/core.state';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { setRecord } from 'src/app/core/record/record.actions';
import { RecordState } from 'src/app/core/record/record.model';
import { selectpendingObsId, selectValidatedObsIds } from 'src/app/core/record/record.selectors';
import { TutoComponent } from 'src/app/shared/components/tuto/tuto.component';
import { Record } from 'src/app/shared/models/record.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ArchiveRecordComponent } from './observations/modals/archive-record/archive-record.component';
import { NewObservationComponent } from './observations/modals/new-obs/new-observation.component';
import { ObservationsListComponent } from './observations/modals/observations-list/observations-list.component';

@Component({
    selector: 'app-record',
    templateUrl: './record.component.html',
    styleUrls: ['./record.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: false
})
export class DossierComponent implements OnInit, OnDestroy {


  isLoading = false;

  createObsLoading = false;

  obsCounter = 0;

  recordId: string;

  min!: number;

  max!: number;

  isObsPaused = false;

  pendingObsId: string | undefined;

  recordData: Record;

  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    public translate: TranslateService,
    public router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private _store: Store<AppState>,
    private _graphql: GraphqlService,
  ) {
    this.recordId = this.route.snapshot.paramMap.get('recordId');
  }

  async ngOnInit() {
    this.isLoading = true;

    this._graphql.getRecordInfo(this.recordId)
      .pipe(
        map(({ data }: any) => data.recordInfo),
        take(1),
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        {
          next: (recordInfo: RecordState) => {
            this._store.dispatch(setRecord(recordInfo))
            this.recordData = recordInfo.record;
            [this.min, this.max] = this.sharedService.getDefaultMinMaxFromBirthday(this.recordData.birthdate);
            this._store.select(selectpendingObsId)
              .pipe(
                takeUntil(this.componentDestroyed$),
              ).subscribe(id => {
                this.isObsPaused = id !== null;
                this.pendingObsId = id;
              })

            this._store.select(selectValidatedObsIds)
              .pipe(
                takeUntil(this.componentDestroyed$),
              ).subscribe(ids => {
                this.obsCounter = ids.length;
              })
          },
          error: (error) => {
            this.sharedService.makeToast(`Une erreur est survenue: ${error}`, '', 'danger');
          }
        }
      )
  }

  onArchiveRecord() {
    const dialogRef = this.dialog.open(ArchiveRecordComponent);
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        this.recordData = {
          ...this.recordData,
          archived: true
        };
        this._store.dispatch(setRecord({ record: this.recordData }))
      })

  }


  onNewObservation() {
    const dialogRef = this.dialog.open(NewObservationComponent, {
      width: '50%',
      data: {
        min: this.min,
        max: this.max,
        recordId: this.recordId,
      },
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        this.createObsLoading = true;
        const [min, max] = this.sharedService.getDefaultMinMaxFromBirthday(this.recordData.birthdate);
        this._graphql.createObs(this.recordId, data.signature, (new Date()).toISOString(), min, max)
          .pipe(
            take(1),
            finalize(() => this.createObsLoading = false))
          .subscribe(
            {
              next: (obs: any) => {
                this._graphql.emptyCache()
                this.onObservation(obs.data.createObs.observation.id);
                this._store.dispatch(setRecord({
                  pendingObsId: obs.data.createObs.observation.id
                }))
              },
              error: (error) => {
                console.error(error);
              }
            }
          )
      }
    });
  }

  onViewFinishedObs() {
    const dialogRef = this.dialog.open(ObservationsListComponent, {
      width: '65%',
      data: {
        recordId: this.recordId,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data?.data) {
        if (data.isNew) {
          this.onNewObservation();
        } else {
          // this.onObservation();
        }
      }
    });
  }

  onViewSynthese() {
    this.router.navigate([`synthese`], { relativeTo: this.route })
  }

  onObservation(id: string) {
    this.router.navigate([`draft/${id}`], { relativeTo: this.route })
  }

  onTuto() {
    const dialogRef = this.dialog.open(TutoComponent, {
      panelClass: 'app-full-bleed-dialog',
      width: '85%',
      height: '95%',
      maxWidth: '100%',
      maxHeight: '100%',
      data: {
        isDossier: true,
      },
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next()
    this.componentDestroyed$.complete()
  }
}
