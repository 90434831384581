import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { finalize, map, take } from 'rxjs/operators';
import { GraphqlService } from '../core/graphql/graphql.service';

@Component({
    selector: 'app-umanweb-superuser',
    templateUrl: './superuser-panel.component.html',
    styleUrls: ['./superuser-panel.component.scss'],
    standalone: false
})
export class SuperuserPanelComponent implements OnInit {

  downloadLoading = false;
  isCreateUserLoading = false;
  constructor(
    private _graphql: GraphqlService
  ) { }

  ngOnInit(): void {
  }

  private formatStats(stats) {

    const stringData = JSON.stringify(stats.map(recordEntry => ({ ..._.omit(recordEntry, "__typename"), obsCount: recordEntry.obsCount.map(obsEntry => ({ [obsEntry.recordNumber]: obsEntry.count })) })), null, 2)
    return stringData.replace("recordCount", "nombre de dossiers").replace("obsCount", "nombre d'observations par numéro de dossier");
  }

  downloadStats() {
    this.downloadLoading = true;
    this._graphql.getAllRecordsCount()
      .pipe(
        take(1),
        map(({ data }: any) => data.allRecordsCount),
        finalize(() => this.downloadLoading = false)
      ).subscribe(stats => {
        stats = this.formatStats(stats);

        const a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([stats], {
          type: "application/json"
        }));

        a.setAttribute("download", "stats.json");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
  }

  async createUser(user: any) {
    this.isCreateUserLoading = true;
    this._graphql.createUser(
      user.firstname,
      user.lastname,
      user.email,
      user.etab,
      user.street,
      user.number,
      user.zip,
      user.city,
      user.password,
    )
      .pipe(
        take(1),
        finalize(() => this.isCreateUserLoading = false)
      )
      .subscribe({
        next: () => alert("utilisateur créé!"),
        error: (error) => alert(error),
      })
  }
}
