import { Component, OnInit } from '@angular/core';
import { finalize, map, take } from 'rxjs/operators';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.scss'],
    standalone: false
})
export class StatsComponent implements OnInit {
  statsLoading = true;
  stats = undefined;
  constructor(
    private _graphql: GraphqlService
  ) { }

  ngOnInit(): void {
    this._graphql.getUserStats()
      .pipe(
        take(1),
        map(({ data }: any) => data),
        finalize(() => this.statsLoading = false)
      )
      .subscribe(stats => this.stats = stats)
  }

}
