<div [ngClass]="{ isModal: isModal, isNotModal: !isModal }">

  <ion-button
    #tooltip="matTooltip"
    matTooltip="translate.instant('back')"
    matTooltipPosition="left"
    matTooltipHideDelay="0"
    matTooltipClass="question-tooltip-box"
    size="small"
    color="primary"
    class="scroll-to-section-button"
    (click)="scrollTop(content)"
    >
    <mat-icon>arrow_upward</mat-icon>
  </ion-button>

  @for (cat of filteredCats; track cat) {
    <ion-button
      #tooltip="matTooltip"
      [matTooltip]="translate.instant(cat.label)"
      matTooltipPosition="left"
      matTooltipHideDelay="0"
      matTooltipClass="question-tooltip-box"
      size="small"
      [color]="cat.value.toLowerCase()"
      class="scroll-to-section-button"
      (click)="scrollToElement(content, cat.value)"
      >
    </ion-button>
  }
</div>
