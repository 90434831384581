import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementLocale, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { map, take } from 'rxjs/operators';
import { AppState } from 'src/app/core/core.state';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { setSubscription } from 'src/app/core/user/user.actions';
import { Subscription } from 'src/app/shared/models/user.model';

@Component({
    selector: 'app-buy-subscription',
    templateUrl: './buy-subscription.component.html',
    styleUrls: ['./buy-subscription.component.scss'],
    standalone: false
})
export class BuySubscriptionComponent implements OnInit {
  plan = "";
  errorMessage = "";
  isCardValid = false;
  isLoading = false;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#FD3F92',
        color: '#000',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#1F1F1F',
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions;

  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _stripeService: StripeService,
    private _translate: TranslateService,
    private _graphql: GraphqlService,
    private _store: Store<AppState>,
  ) {
    this.elementsOptions = {
      locale: this._translate.currentLang as StripeElementLocale,
    };
  }

  ngOnInit(): void {
    this._route.queryParams
      .subscribe(params => {
        this.plan = params.plan;
      }
      );
  }

  billingSuccess(sub: Subscription) {
    this.isLoading = false;
    this._store.dispatch(setSubscription({
      subscription: {
        status: sub.status,
        subscriptionId: sub.subscriptionId,
        currentPeriodEnd: sub.currentPeriodEnd,
        cancelAt: sub.cancelAt,
        cancelAtPeriodEnd: sub.cancelAtPeriodEnd,
      }
    }))
    this._router.navigateByUrl('/regularizePayment/subscriptionSuccess')
  }

  cardChange(ev: StripeCardElementChangeEvent) {
    this.isCardValid = !ev.error
  }
  createToken() {

    this.errorMessage = "";
    this.isLoading = true;
    this._stripeService.createPaymentMethod({
      type: 'card',
      card: this.card.element,
      billing_details: { name: null },
    }).subscribe((result) => {

      if (result.paymentMethod) {
        this._graphql.subscribeToPlan(result.paymentMethod.id, this.plan)
          .pipe(
            take(1),
            map(({ data }: any) => data.subscribe.subscription)
          )
          .subscribe(
            {
              next: sub => {
                switch (sub.status) {
                  case "active":
                    this.billingSuccess(sub);
                    break;
                  case "incomplete":
                    this._stripeService.confirmCardPayment(sub.clientSecret)
                      .pipe(take(1))
                      .subscribe(
                        {
                          next: (result) => {
                            if (result.error) {
                              this.isLoading = false;
                              this.errorMessage = result.error.message;
                            }
                            else {
                              this.billingSuccess({ ...sub, status: 'active' })
                            }

                          },
                          error: (error) => {
                            this.isLoading = false;
                            this.errorMessage = error.message;
                          }
                        }
                      )
                    break;
                  default:
                    this.isLoading = false;
                    this.errorMessage = `Error while finalizing the subscription. Subscription status: ${sub.status}`;
                    break;
                }
              },
              error: (error) => {
                this.isLoading = false;
                this.errorMessage = error.message;
              }
            }
          )
      } else if (result.error) {
        this.isLoading = false;
        this.errorMessage = result.error.message;
      }
    });
  }

}
