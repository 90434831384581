import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    templateUrl: './ref.component.html',
    styleUrls: ['./ref.component.scss'],
    standalone: false
})
export class RefComponent implements OnInit {
  isLoading = false;
  constructor(
    public router: Router,
  ) { }

  ngOnInit(): void {
  
  }

  goToRef() {
    this.router.navigate(['start', 'ref', 'RefTheorique']);
  }

  goToPro() {
    this.router.navigate(['start', 'ref', 'pros']);
  }

  goToDef() {
    this.router.navigate(['start', 'ref', 'def']);
  }

  goToLib() {
    this.router.navigate(['start', 'ref', 'biblio']);
  }
}
