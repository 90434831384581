import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-regularize-payment',
    templateUrl: './regularize-payment.component.html',
    styleUrls: ['./regularize-payment.component.scss'],
    standalone: false
})
export class RegularizePaymentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
