<ion-content class="has-header" overflow-scroll="true">
  <ion-row style="position: relative; min-height: 100%">
    <ion-col
      style="margin-bottom: 80px"
      size="12"
      size-lg="10"
      offset-lg="1"
      size-xl="10"
      offset-xl="1"
      >
      <app-header
        title="references"
        backTitle="Accueil"
        link="recherche"
        show="true"
      ></app-header>
      @if (isLoading) {
        <ion-progress-bar
          type="indeterminate"
        ></ion-progress-bar>
      }
      @if (!isLoading) {
        <div>
          <div class="flex-grid">
            <app-card
              color="#2A435B"
              title="theoreticalReferences"
              icon="school"
              iconOnly="true"
              button1="consult"
              (button1Clicked)="goToRef()"
            ></app-card>
            <app-card
              color="#2A435B"
              title="professionals"
              icon="accessibility"
              iconOnly="true"
              button1="consult"
              (button1Clicked)="goToPro()"
            ></app-card>
          </div>
          <div class="flex-grid">
            <app-card
              color="#2A435B"
              title="definitions"
              icon="signpost"
              iconOnly="true"
              button1="consult"
              (button1Clicked)="goToDef()"
            ></app-card>
            <app-card
              color="#2A435B"
              title="bibliography"
              icon="list"
              iconOnly="true"
              button1="consult"
              (button1Clicked)="goToLib()"
            ></app-card>
          </div>
        </div>
      }
    </ion-col>
    <app-footer></app-footer>
  </ion-row>
</ion-content>
