import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA } from '@angular/material/dialog';
import CATEGORIES from "src/assets/json/CATEGORIES.json";
import { LoadingController } from '@ionic/angular';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { finalize, map, take } from 'rxjs/operators';
import { Obs } from 'src/app/shared/models/obs.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/core.state';
import { selectRecordBirthdate, selectRecordGender, selectRecordId, selectRecordNumber } from 'src/app/core/record/record.selectors';
import { obsRep } from 'src/app/shared/models/obsRep.model';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './view-mode.component.html',
    styleUrls: ['./view-mode.component.scss'],
    standalone: false
})
export class ViewModeComponent implements OnInit {
  filteredQues = [];

  isStart = true;

  isEnd = false;

  isScrolled: boolean;

  selectedQuesRep: string;

  isLoading = true;

  obs: Obs;

  birthdate: string;

  gender: string;

  recordNumber: number;

  recordId:string;

  isVisible = false;

  barChartCategories: string[];

  answers: { [answerCode: string]: obsRep };

  ageAtObs: number;

  min: number;

  max: number;

  @ViewChild('slides') slides: ElementRef;

  slideOpts = {
    allowTouchMove: false,
    speed: 400,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    slidesPerView: 1,
  };


  categoriesSettings = {
    "socialAffective": {
      cat: 'A',
      colors: [
        '#f1bb62',
        '#EEAA3C',
        '#be8830',
        '#8e6624',
        '#5f4418',
      ],
      legends: [
        "QUESTIONS.socialAffective_1",
        "QUESTIONS.socialAffective_2",
        "QUESTIONS.socialAffective_3",
        "QUESTIONS.socialAffective_4",
        "QUESTIONS.socialAffective_5",
      ],
      subcats: [
        '1',
        '2',
        '3',
        '4',
        '5',
      ]
    },
    "cognitive": {
      cat: 'B',
      colors: [
        '#AAD080',
        '#86BD4A',
        '#5D8433',
      ],
      legends: [
        "QUESTIONS.cognitive_1",
        "QUESTIONS.cognitive_2",
        "QUESTIONS.cognitive_3",
      ],
      subcats: [
        '1',
        '2',
        '3',
      ]
    },
    "language": {
      cat: 'C',
      colors: [
        '#E78B64',
        '#E26E3E',
        '#9E4D2B',
        '#5A2C18',
      ],
      legends: [
        "QUESTIONS.language_1",
        "QUESTIONS.language_2",
        "QUESTIONS.language_3",
        "QUESTIONS.language_4",
      ],
      subcats: [
        '1',
        '2',
        '3',
        '4',
      ]
    },
    "motor": {
      cat: 'D',
      colors: [
        '#bce2f0',
        '#9ad4e8',
        '#79c6e1',
        '#58B8DA',
        '#4693ae',
        '#346e82',
        '#234957',
      ],
      legends: [
        "QUESTIONS.motor_1",
        "QUESTIONS.motor_2",
        "QUESTIONS.motor_3",
        "QUESTIONS.motor_4",
        "QUESTIONS.motor_5",
        "QUESTIONS.motor_6",
        "QUESTIONS.motor_7",
      ],
      subcats: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    },
    "somatic": {
      cat: 'E',
      colors: [
        '#e2d3e1',
        '#cdb2cb',
        '#B891B5',
        '#937490',
      ],
      legends: [
        "QUESTIONS.somatic_1",
        "QUESTIONS.somatic_2",
        "QUESTIONS.somatic_3",
        "QUESTIONS.somatic_4",
      ],
      subcats: [
        '1',
        '2',
        '3',
        '4',
      ]
    }
  }
  get barData() {
    const categories = [
      'A',
      'B',
      'C',
      'D',
      'E'
    ];
    //returns a 2D array [X][Y] where X are the categories("assimilé",etc) and Y are the number of answer by level("s'excerse", etc) for the x
    return Object.keys(this.answers)
      .reduce((accu, answerCode) => {
        accu[categories.indexOf(answerCode.charAt(0))][this.answers[answerCode].level - 1] += 1;
        return accu;
      }, [
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
      ])
  }

  subCatBarData(category) {
    const data = Array.from({ length: this.categoriesSettings[category].subcats.length }, e => Array(5).fill(0));
    Object.keys(this.answers).filter(key => key.charAt(0) == this.categoriesSettings[category].cat)
      .forEach(answerCode => {
        data[this.categoriesSettings[category].subcats.indexOf(answerCode.charAt(1))][this.answers[answerCode].level - 1] += 1;
      })
    return data;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _graphql: GraphqlService,
    private _translate: TranslateService,
    private _sharedService: SharedService,
    private _store: Store<AppState>,
    private _route: ActivatedRoute,
    public loadingCtrl: LoadingController
  ) {
    this.barChartCategories = [
      "socialAffective",
      "cognitive",
      "language",
      "motor",
      "somatic",
    ]
  }

  ngOnInit(): void {
    const obsId = this._route.snapshot.paramMap.get('obsId');
    this.isLoading = true;
    this._graphql.getObs(obsId)
      .pipe(
        take(1),
        map(({ data }: any) => data.observation),
        finalize(() => this.isLoading = false))
      .subscribe((obs: any) => {
        this.obs = obs
        this._store.select(selectRecordBirthdate)
          .pipe(take(1))
          .subscribe(birthdate => {
            this.ageAtObs = this._sharedService.getAgeFromBirthdate(birthdate) - this._sharedService.getAgeFromBirthdate(this.obs.date)
          })
      })
    this._store.select(selectRecordNumber).pipe(take(1)).subscribe(number => this.recordNumber = number)
    this._store.select(selectRecordBirthdate).pipe(take(1)).subscribe(birthdate => this.birthdate = birthdate)
    this._store.select(selectRecordGender).pipe(take(1)).subscribe(gender => this.gender = gender)
    this._store.select(selectRecordId).pipe(take(1)).subscribe(id => this.recordId = id)
  }

  public get filteredCats() {
    const filteredCats = [...new Set(this.filteredQues.filter(element => element.length > 0).map(element => element[0].category1Rk))]
    return CATEGORIES.filter(cat => filteredCats.includes(cat.value))
  }

  async onLoadingStarted() {
    if (!this.isVisible) {
      this.isVisible = true;
      const loading = await this.loadingCtrl.create({
        message: this._translate.instant("generatePDF"),
        duration: 5000,
      });
      await loading.present();
      loading.onDidDismiss().then(() => {
        this.isVisible = false;
      });
    }
  }

  onLoadingEnded() {
    this.loadingCtrl.dismiss();
  }

  slideChanged() {
    this.slides.nativeElement.isEnd().then((res) => {
      this.isEnd = res;
    });
    this.slides.nativeElement.isBeginning().then((res) => {
      this.isStart = res;
    });
  }

  onScroll(ev) {
    if (ev.detail.currentY > 50) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  updateAnswers(answers) {
    this.answers = answers;
    this.min = Math.min(...Object.values(answers).map((answer:obsRep) => answer.question.startMonth));
    this.max = Math.max(...Object.values(answers).map((answer:obsRep) => answer.question.endMonth));

  }

  scrollTop(content) {
    content.scrollToTop(1500);
  }

  slidePrev() {
    this.slides.nativeElement.slidePrev();
  }

  slideNext() {
    this.slides.nativeElement.slideNext();
  }
}

