import { AgePipe } from 'src/app/shared/pipes/age.pipe';

import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import domtoimage from 'dom-to-image';
import { formatDate } from '@angular/common';
import { obsRep } from 'src/app/shared/models/obsRep.model';
import { AppState } from 'src/app/core/core.state';
import { Store } from '@ngrx/store';
import { selectRecordBirthdate, selectRecordNumber } from 'src/app/core/record/record.selectors';
import { take } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-pdf',
    templateUrl: './pdf.component.html',
    styleUrls: ['./pdf.component.scss'],
    standalone: false
})
export class PDFComponent implements OnInit {

  selectedCatTitle: string;//TODO customize
  selectedQuesRepTitle: string;//TODO customize
  selectedTypeTitle: string;//TODO customize

  spiderChart: string = '';
  barChart: string = '';
  subBarChart0: string = '';
  subBarChart1: string = '';
  subBarChart2: string = '';
  subBarChart3: string = '';
  subBarChart4: string = '';

  @Input() filteredQues = [];

  @Input() min: string;

  @Input() max: string;

  @Input() obsId: string;

  @Input() signature: string;

  recordNumber: number;

  ageAtObs: number;

  @Input() isObs = false;

  @Input() isDraft = false;

  @Input() dateObs: Date;

  @Input() answers: { [answerCode: string]: obsRep };

  age: any;

  avecRep: number;

  totalQues: number;

  dateTitle: string;

  pdfLoading = false;

  constructor(
    private agePipe: AgePipe,
    private _sharedService: SharedService,
    private _translate: TranslateService,
    private _store: Store<AppState>
  ) { }
  ngOnInit(): void {
    this.selectedCatTitle = this._translate.instant('domains');//TODO customize
    this.selectedQuesRepTitle = this._translate.instant('answers');//TODO customize
    this.selectedTypeTitle = this._translate.instant('questions');//TODO customize
    this._store.select(selectRecordBirthdate)
      .pipe(take(1))
      .subscribe(async birthdate => this.ageAtObs = this._sharedService.getAgeFromBirthdate(birthdate) - this._sharedService.getAgeFromBirthdate(this.dateObs))
    this._store.select(selectRecordNumber)
      .pipe(take(1))
      .subscribe(number => this.recordNumber = number)

  }

  getCatName(cat) {
    switch (cat) {
      case 0:
        return this._translate.instant("socialAffective");
      case 1:
        return this._translate.instant("cognitive");
      case 2:
        return this._translate.instant("language");
      case 3:
        return this._translate.instant("motor");
      case 4:
        return this._translate.instant("somatic");
      default:
        return this._translate.instant("domains");
    }
  }
  getQuestionFilterName(cat) {
    switch (cat) {
      case "starting":
        return this._translate.instant("learningStart");
      case "until":
        return this._translate.instant("learningEnd");
      case "red":
        return this._translate.instant("learningLate");
      default:
        return this._translate.instant("questions");
    }
  }
  getLevelFilterName(cat) {
    switch (cat) {
      case 1:
        return this._translate.instant("unconcerned");
      case 2:
        return this._translate.instant("nonAccess");
      case 3:
        return this._translate.instant("exercising");
      case 4:
        return this._translate.instant("integrating");
      case 5:
        return this._translate.instant("assimilated");
      default:
        return this._translate.instant("answers");
    }
  }
  getColor(cat1) {
    let clr: string;
    if (cat1 === 'A') {
      clr = '#eeaa3c';
    } else if (cat1 === 'B') {
      clr = '#86bd4a';
    } else if (cat1 === 'C') {
      clr = '#e26e3e';
    } else if (cat1 === 'D') {
      clr = '#58b8da';
    } else if (cat1 === 'E') {
      clr = '#b891b5';
    }
    return clr;
  }

  getColor2(cat1, level, rep) {
    if (level === rep) {
      return this.getColor(cat1);
    } else {
      return '#F5F5F5';
    }
  }

  initData() {
    let d = new Date();
    let ye = new Intl.DateTimeFormat('fr', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('fr', { month: '2-digit' }).format(d);
    let da = new Intl.DateTimeFormat('fr', { day: '2-digit' }).format(d);
    this.dateTitle = `${ye}-${mo}-${da}`;
    if (this.isObs) {
      this.avecRep = Object.keys(this.answers).filter(answerCode => this.filteredQues.find(questions => questions.find(ques => ques.answerCode == answerCode))).length;
      this.totalQues = this.filteredQues.reduce((accu: number, val) => accu + val.length, 0);
    }
  }

  checkIfObs() {
    if (this.isObs) {
      const date = formatDate(this.dateObs, 'longDate', 'fr-FR');
      const column = {
        text: this._translate.instant("viewObsTitle") + date,
        alignment: 'center',
        fontSize: '14',
        bold: false,
        margin: [0, 0, 0, 20],
      };
      return column;
    } else {
      const column = {
        text: this._translate.instant("synthesis"),
        alignment: 'center',
        fontSize: '14',
        bold: false,
        margin: [0, 0, 0, 20],
      };
      return column;
    }
  }

  start() {
    this.pdfLoading = true;
    const filtersCache = JSON.parse(localStorage.getItem('UMANWEB-SEARCHFILTERS'))[this.obsId];
    if (filtersCache) {
      this.selectedCatTitle = filtersCache.selectedCats.map(cat => this.getCatName(cat)).join(", ") || this._translate.instant("domains");
      this.selectedTypeTitle = this.getQuestionFilterName(filtersCache.questionFilter) || this._translate.instant("questions");
      if (filtersCache.answerFilter == "noRep") {
        this.selectedQuesRepTitle = this._translate.instant("withoutAnswer");
      }
      else if (filtersCache.answerFilter == "rep") {
        this.selectedQuesRepTitle = filtersCache.learningLevelFilter.map(elem => this.getLevelFilterName(elem)).join(", ");
      }
    }
    if (this.isDraft) {
      this.initData();
      this.generatePDF();
    }
    else {
      Promise.all(
        [
          domtoimage.toPng(document.getElementById('spiderChart')),
          domtoimage.toPng(document.getElementById('barChart')),
          domtoimage.toPng(document.getElementById('subBarChart0')),
          domtoimage.toPng(document.getElementById('subBarChart1')),
          domtoimage.toPng(document.getElementById('subBarChart2')),
          domtoimage.toPng(document.getElementById('subBarChart3')),
          domtoimage.toPng(document.getElementById('subBarChart4')),
        ])
        .then((dataUrl: string[]) => {
          this.spiderChart = dataUrl[0];
          this.barChart = dataUrl[1];
          this.subBarChart0 = dataUrl[2];
          this.subBarChart1 = dataUrl[3];
          this.subBarChart2 = dataUrl[4];
          this.subBarChart3 = dataUrl[5];
          this.subBarChart4 = dataUrl[6];
          this.initData();
          this.generatePDF();
        })
        .catch((error: string) => {
          this.pdfLoading = false;
          console.error('oops, something went wrong!', error);
        });
    }
  }

  async generatePDF() {

    const learningLate = ([].concat(...this.filteredQues)).filter(ques => this.answers[ques.answerCode]?.level < 5 && ques.direction == 'until' && ques.endMonth < this.ageAtObs)
    const learningEarly = ([].concat(...this.filteredQues)).filter(ques => this.answers[ques.answerCode]?.level == 5 && ques.endMonth > this.ageAtObs)
    const earlyLateSkillsBody = [];
    if (learningEarly.length > 0) {
      earlyLateSkillsBody.push([
        {
          text: this._translate.instant("learningEarlyCount"),
          alignment: 'left',
          fontSize: '12',
          bold: false,
          color: '#55c40c',
          border: [false, false, false, false],
          margin: [8, 8, 0, 0],
        },
      ]);
      learningEarly.forEach((ques, index) => earlyLateSkillsBody.push([
        {
          text: `${index + 1}. ` + this._translate.instant('QUESTIONS.' + ques.answerCode + '_name'),
          border: [false, false, false, false],
        }
      ]))
    }
    if (learningLate.length > 0) {
      earlyLateSkillsBody.push([
        {
          text: this._translate.instant("learningLateCount"),
          alignment: 'left',
          fontSize: '12',
          bold: false,
          color: '#b51c1c',
          border: [false, false, false, false],
          margin: [8, 8, 0, 0],
        },
      ]);

      learningLate.forEach((ques, index) => earlyLateSkillsBody.push([
        {
          text: `${index + 1}. ` + this._translate.instant('QUESTIONS.' + ques.answerCode + '_name'),
          border: [false, false, false, false],
        }
      ]))
    }

    const rows = [];
    const logo = `
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.8146 35.1429L28.8146 14.5839H31.6443L35.427 25.4697H35.4847L39.2385 14.5839H42.0971L42.0971 35.1429H39.1518L39.1518 22.64H39.0941L36.1777 31.4757L34.7051 31.4757L31.8176 22.64H31.7598L31.7598 35.1429H28.8146Z" fill="black"/>
      <path d="M15.629 14.5839L15.629 30.5229C15.629 31.1966 15.5039 31.8222 15.2537 32.3997C15.0227 32.958 14.6858 33.4585 14.243 33.9012C13.8003 34.344 13.2902 34.6905 12.7127 34.9407C12.1352 35.191 11.5192 35.3161 10.8647 35.3161C10.2102 35.3161 9.59417 35.191 9.01667 34.9407C8.45842 34.6905 7.95792 34.344 7.51517 33.9012C7.07242 33.4585 6.72592 32.958 6.47567 32.3997C6.22542 31.8222 6.10029 31.1966 6.10029 30.5229L6.10029 14.5839H9.04554L9.04554 30.2341C9.04554 30.9656 9.21879 31.5046 9.56529 31.8511C9.91179 32.1976 10.3449 32.3709 10.8647 32.3709C11.3844 32.3709 11.8175 32.1976 12.164 31.8511C12.5105 31.5046 12.6838 30.9656 12.6838 30.2341L12.6838 14.5839L15.629 14.5839Z" fill="black"/>
      <line x1="22.6607" y1="11.1429" x2="22.6607" y2="40.2857" stroke="black" stroke-width="0.75"/>
      </svg>
      `;
    const starting_parenthesis = `
      <svg width="4" height="24" viewBox="0 0 4 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 0H4V2H2V22H4V24H1C0.447715 24 0 23.5523 0 23V1C0 0.447715 0.447715 0 1 0Z" fill="#545454"/>
      </svg>
      `;
    const starting_bar = `
      <svg width="56" height="2" viewBox="0 0 56 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M56 2V0H0V2H56Z" fill="url(#paint0_linear)"/>
      <defs>
      <linearGradient id="paint0_linear" x1="-9.1269e-08" y1="0.999999" x2="56" y2="0.999997" gradientUnits="userSpaceOnUse">
      <stop stop-color="#545454"/>
      <stop offset="1" stop-color="#545454" stop-opacity="0"/>
      </linearGradient>
      </defs>
      </svg>
      `;
    const until_parenthesis = `
      <svg width="4" height="24" viewBox="0 0 4 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 0H0V2H2V22H0V24H3C3.55228 24 4 23.5523 4 23V1C4 0.447715 3.55228 0 3 0Z" fill="#545454"/>
      </svg>
      `;
    const until_bar = `
      <svg width="56" height="2" viewBox="0 0 56 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M56 2V0H0V2H56Z" fill="url(#paint0_linear)"/>
      <defs>
      <linearGradient id="paint0_linear" x1="56" y1="1.00001" x2="2.77615e-07" y2="1.00003" gradientUnits="userSpaceOnUse">
      <stop stop-color="#545454"/>
      <stop offset="1" stop-color="#545454" stop-opacity="0"/>
      </linearGradient>
      </defs>
      </svg>
      `;
    const emptySvg = `
      <svg width="4" height="24" viewBox="0 0 4 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 0H4V2H2V22H4V24H1C0.447715 24 0 23.5523 0 23V1C0 0.447715 0.447715 0 1 0Z" fill="#FFFFFF"/>
      </svg>
      `;

    let openParenthesis = '';
    let topBar = '';
    let bottomBar = '';
    let closeParenthesis = '';

    let curCat1Code = '';
    let curCat2Code = 0;

    if (this.selectedCatTitle === this._translate.instant("domains")) {
      this.selectedCatTitle = this._translate.instant("everything");
    }
    if (this.selectedTypeTitle === this._translate.instant("questions")) {
      this.selectedTypeTitle = this._translate.instant("everything");
    }
    if (this.selectedQuesRepTitle === this._translate.instant("answers")) {
      this.selectedQuesRepTitle = this._translate.instant("everything");
    }
    if (this.isObs) {

      this.filteredQues.forEach((element, i, arr) => {
        element.forEach(async (el) => {
          if (el.direction === 'until') {
            this.age = await this.agePipe.transform(el.endMonth);
            openParenthesis = emptySvg;
            topBar = until_bar;
            bottomBar = until_bar;
            closeParenthesis = until_parenthesis;
          } else if (el.direction === 'starting') {
            this.age = await this.agePipe.transform(el.startMonth);
            openParenthesis = starting_parenthesis;
            topBar = starting_bar;
            bottomBar = starting_bar;
            closeParenthesis = emptySvg;
          }

          if (curCat1Code !== el.category1Rk) {
            curCat1Code = el.category1Rk;
            rows.push([
              {
                text: this._translate.instant(el.category1NameLong),
                border: [false, false, false, false],
                margin: [0, 32, 0, 8],
                colSpan: 2,
                style: 'cat1Title',
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ]);
          }

          if (curCat2Code !== el.category2Rk) {
            curCat2Code = el.category2Rk;
            rows.push([
              {
                table: {
                  widths: [14, 160],
                  heights: [1, 14, 1],
                  body: [
                    ['', ''],
                    [
                      {
                        svg: `
                          <svg width="8" height="8" viewBox="0 0 8 8">
                            <rect width="8" height="8" fill="${this.getColor(
                          el.category1Rk
                        )}"/>
                          </svg>
                        `,
                        border: [false, false, false, false],
                        margin: [4, 4, 0, 0],
                      },
                      {
                        text: this._translate.instant("QUESTIONS." + el.category1Name + '_' + el.category2Rk),
                        border: [false, false, false, false],
                        style: 'cat2Title',
                        margin: [4, 0, 0, 0],
                      },
                    ],
                    ['', ''],
                  ],
                },
                layout: 'noBorders',
                border: [false, false, false, false],
                margin: [4, 16, 0, 8],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ]);
            rows.push([
              {
                text: '',
                border: [false, false, false, true],
              },
              {
                text: '',
                border: [false, false, false, true],
              },
              {
                text: this._translate.instant("unconcerned"),
                border: [false, false, false, true],
                style: 'tableHeader',
              },
              {
                text: this._translate.instant("nonAccess"),
                border: [false, false, false, true],
                style: 'tableHeader',
              },
              {
                text: this._translate.instant("exercising"),
                border: [false, false, false, true],
                style: 'tableHeader',
              },
              {
                text: this._translate.instant("integrating"),
                border: [false, false, false, true],
                style: 'tableHeader',
              },
              {
                text: this._translate.instant("assimilated"),
                border: [false, false, false, true],
                style: 'tableHeader',
              },
            ]);
          }
          rows.push([
            {
              table: {
                widths: [2, 56, 2],
                heights: [1, 18, 1],
                body: [
                  [
                    {
                      svg: openParenthesis,
                      width: 4,
                      height: 24,
                      rowSpan: 3,
                      margin: [0, 0, 0, 0],
                    },
                    {
                      svg: topBar,
                      margin: [0, 0, 0, 0],
                    },
                    {
                      svg: closeParenthesis,
                      width: 4,
                      height: 24,
                      rowSpan: 3,
                      margin: [0, 0, 0, 0],
                    },
                  ],
                  [
                    {
                      text: '',
                      margin: [0, 0, 0, 0],
                    },
                    {
                      text: this.age,
                      alignment: 'center',
                      fontSize: 8,
                      margin: [0, 3, 0, 0],
                    },
                    {
                      text: '',
                      margin: [0, 0, 0, 0],
                    },
                  ],
                  [
                    {
                      text: '',
                      margin: [0, 0, 0, 0],
                    },
                    {
                      svg: bottomBar,
                      margin: [0, 0, 0, 0],
                    },
                    {
                      text: '',
                      margin: [0, 0, 0, 0],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return 0;
                },
                vLineWidth: function (i) {
                  return 0;
                },
                hLineColor: function (i) {
                  return i === 1 ? 'black' : '#757575';
                },
                paddingLeft: function (i) {
                  return 0;
                },
                paddingRight: function (i, node) {
                  return 0;
                },
                paddingTop: function (i, node) {
                  return 1;
                },
                paddingBottom: function (i, node) {
                  return 0;
                },
              },
              border: [false, false, false, true],
              margin: [0, 2, 0, 2],
            },
            {
              text: this._translate.instant("QUESTIONS." + el.answerCode + "_name"),
              border: [false, false, false, true],
              margin: [0, 8, 0, 8],
            },
            {
              svg: `
                <svg width="32" height="8" viewBox="0 0 32 8">
                  <rect width="32" height="8" fill="${this.getColor2(
                el.category1Rk,
                this.answers[el.answerCode]?.level,
                1
              )}"/>
                </svg>
              `,
              border: [false, false, false, true],
              margin: [4, 11, 0, 8],
            },
            {
              svg: `
                <svg width="32" height="8" viewBox="0 0 32 8">
                  <rect width="32" height="8" fill="${this.getColor2(
                el.category1Rk,
                this.answers[el.answerCode]?.level,
                2
              )}"/>
                </svg>
              `,
              border: [false, false, false, true],
              margin: [4, 11, 0, 8],
            },
            {
              svg: `
                <svg width="32" height="8" viewBox="0 0 32 8">
                  <rect width="32" height="8" fill="${this.getColor2(
                el.category1Rk,
                this.answers[el.answerCode]?.level,
                3
              )}"/>
                </svg>
              `,
              border: [false, false, false, true],
              margin: [4, 11, 0, 8],
            },
            {
              svg: `
                <svg width="32" height="8" viewBox="0 0 32 8">
                  <rect width="32" height="8" fill="${this.getColor2(
                el.category1Rk,
                this.answers[el.answerCode]?.level,
                4
              )}"/>
                </svg>
              `,
              border: [false, false, false, true],
              margin: [4, 11, 0, 8],
            },
            {
              svg: `
                <svg width="32" height="8" viewBox="0 0 32 8">
                  <rect width="32" height="8" fill="${this.getColor2(
                el.category1Rk,
                this.answers[el.answerCode]?.level,
                5
              )}"/>
                </svg>
              `,
              border: [false, false, false, true],
              margin: [4, 11, 0, 8],
            },
          ]);
        });
      });
    }

    const pdf = {
      info: {
        title: `${this._translate.instant("record")} ${this.recordNumber} - ${this.isObs ? `${this._translate.instant("report")} ${this.dateTitle}` : 'Synthèse'}`,
        author: 'TODO',
        creator: 'UmanWeb',
      },
      pageMargins: [40, 16, 40, 40],
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Page ' + currentPage.toString() + ' / ' + pageCount,
            alignment: 'center',
          },
        ];
      },
      pageSize: 'A4',
      pageOrientation: 'portrait',

      content: [
        {
          table: {
            widths: [150, 200, 150],
            body: [
              [
                {
                  rowSpan: 4,
                  svg: logo,
                  border: [false, false, false, false],
                },
                {
                  text: '',
                  border: [false, false, false, false],
                },
                {
                  text: '',
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: '',
                  border: [false, false, false, false],
                },
                {
                  text: '',
                  border: [false, false, false, false],
                },
                {
                  text: this._translate.instant("fullname"),
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: '',
                  border: [false, false, false, false],
                },
                {
                  text: `${this._translate.instant("record")} ${this.recordNumber}`,
                  alignment: 'center',
                  fontSize: '16',
                  bold: false,
                  margin: [0, 0, 0, 0],
                  border: [false, false, false, false],
                },
                {
                  text: ' ',
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: '',
                  border: [false, false, false, false],
                },
                {
                  text: this.checkIfObs(),
                  border: [false, false, false, false],
                },
                {
                  text: ' ',
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: '',
                  border: [false, false, false, false],
                },
                {
                  text: this.signature ? `${this._translate.instant("createdBy")}: ${this.signature}` : '',
                  alignment: 'center',
                  fontSize: '11',
                  bold: false,
                  margin: [0, 0, 0, 0],
                  border: [false, false, false, false],
                },
                {
                  text: ' ',
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
        !this.isObs ? '' : {
          columns: [
            {
              table: {
                widths: [72, 72],
                body: [
                  [
                    {
                      text: this._translate.instant("infos"),
                      alignment: 'center',
                      fontSize: '12',
                      colSpan: 2,
                      border: [false, false, false, true],
                      margin: [0, 0, 0, 4],
                    },
                    {
                      text: '',
                      border: [false, false, false, false],
                      margin: [0, 0, 0, 0],
                    },
                  ],
                  [
                    {
                      text: this._translate.instant("questions"),
                      alignment: 'left',
                      fontSize: '10',
                      bold: true,
                      border: [false, false, false, false],
                      margin: [8, 4, 0, 0],
                    },
                    {
                      text: this.totalQues,
                      alignment: 'right',
                      fontSize: '10',
                      bold: false,
                      border: [false, false, false, false],
                      margin: [0, 4, 8, 0],
                    },
                  ],
                  [
                    {
                      text: this._translate.instant("answers"),
                      alignment: 'left',
                      fontSize: '10',
                      bold: true,
                      border: [false, false, false, false],
                      margin: [8, 4, 0, 0],
                    },
                    {
                      text: this.avecRep,
                      alignment: 'right',
                      fontSize: '10',
                      bold: false,
                      border: [false, false, false, false],
                      margin: [0, 4, 8, 0],
                    },
                  ],
                  [
                    {
                      text: this._translate.instant("obsDate"),
                      alignment: 'left',
                      fontSize: '10',
                      bold: true,
                      border: [false, false, false, false],
                      margin: [8, 4, 0, 0],
                    },
                    {
                      text: formatDate(this.dateObs, 'longDate', 'fr-FR'),
                      alignment: 'right',
                      fontSize: '10',
                      bold: false,
                      border: [false, false, false, false],
                      margin: [0, 4, 8, 0],
                    },
                  ],
                ],
              },
              border: [false, false, false, false],
              margin: [0, 16, 0, 8],
              width: 'auto',
            },
            {
              table: {
                widths: [72, 72],
                body: [
                  [
                    {
                      text: this._translate.instant("periodAndAge"),
                      alignment: 'center',
                      fontSize: '12',
                      colSpan: 2,
                      border: [false, false, false, true],
                      margin: [0, 0, 0, 4],
                    },
                    {
                      text: '',
                      border: [false, false, false, false],
                      margin: [4, 0, 0, 0],
                    },
                  ],
                  [
                    {
                      text: this._translate.instant("ageAtObs"),
                      alignment: 'left',
                      fontSize: '10',
                      bold: true,
                      border: [false, false, false, false],
                      margin: [8, 4, 0, 0],
                    },
                    {
                      text: await this.agePipe.transform(this.ageAtObs),
                      alignment: 'right',
                      fontSize: '10',
                      bold: false,
                      border: [false, false, false, false],
                      margin: [0, 4, 8, 0],
                    },
                  ],
                  [
                    {
                      text: this._translate.instant("observedPeriod"),
                      alignment: 'left',
                      fontSize: '10',
                      bold: true,
                      border: [false, false, false, false],
                      margin: [8, 4, 0, 0],
                    },
                    {
                      text: `${this.min} - ${this.max}`,
                      alignment: 'right',
                      fontSize: '10',
                      bold: false,
                      border: [false, false, false, false],
                      margin: [0, 4, 8, 0],
                    },
                  ],
                ],
              },
              border: [false, false, false, false],
              margin: [4, 16, 4, 8],
              width: 'auto',
            },
            {
              table: {
                widths: [72, 72],
                body: [
                  [
                    {
                      text: this._translate.instant("filtres"),
                      alignment: 'center',
                      fontSize: '12',
                      colSpan: 2,
                      border: [false, false, false, true],
                      margin: [0, 0, 0, 4],
                    },
                    {
                      text: '',
                      border: [false, false, false, false],
                      margin: [0, 0, 0, 0],
                    },
                  ],
                  [
                    {
                      text: this._translate.instant("domains"),
                      alignment: 'left',
                      fontSize: '10',
                      bold: true,
                      border: [false, false, false, false],
                      margin: [8, 4, 0, 0],
                    },
                    {
                      text: this.selectedCatTitle,
                      alignment: 'right',
                      fontSize: '10',
                      bold: false,
                      border: [false, false, false, false],
                      margin: [0, 4, 8, 0],
                    },
                  ],
                  [
                    {
                      text: this._translate.instant("questions"),
                      alignment: 'left',
                      fontSize: '10',
                      bold: true,
                      border: [false, false, false, false],
                      margin: [8, 4, 0, 0],
                    },
                    {
                      text: this.selectedTypeTitle,
                      alignment: 'right',
                      fontSize: '10',
                      bold: false,
                      border: [false, false, false, false],
                      margin: [0, 4, 8, 0],
                    },
                  ],
                  [
                    {
                      text: this._translate.instant("answers"),
                      alignment: 'left',
                      fontSize: '10',
                      bold: true,
                      border: [false, false, false, false],
                      margin: [8, 4, 0, 0],
                    },
                    {
                      text: this.selectedQuesRepTitle,
                      alignment: 'right',
                      fontSize: '10',
                      bold: false,
                      border: [false, false, false, false],
                      margin: [0, 4, 8, 0],
                    },
                  ],
                ],
              },
              border: [false, false, false, false],
              margin: [0, 16, 0, 8],
              width: 'auto',
            },
          ],
          columnGap: 16,
        },
        this.isDraft ? '' : {
          columns: [
            {
              margin: [0, 20, 0, 0],
              width: 500,
              height: 250,
              alignment: 'center',
              image: this.spiderChart,
            }
          ],
        },
        this.isDraft ? '' : {
          columns: [
            {
              margin: [0, 20, 0, 0],
              width: 500,
              height: 250,
              alignment: 'center',
              image: this.barChart,
            },
          ],
        },
        this.isDraft ? '' : {
          columns: [
            {
              margin: [0, 20, 0, 0],
              width: 500,
              height: 250,
              alignment: 'center',
              image: this.subBarChart0,
            },
          ],
        },
        this.isDraft ? '' : {
          columns: [
            {
              margin: [0, 20, 0, 0],
              width: 500,
              height: 250,
              alignment: 'center',
              image: this.subBarChart1,
            },
          ],
        },
        this.isDraft ? '' : {
          columns: [
            {
              margin: [0, 20, 0, 0],
              width: 500,
              height: 250,
              alignment: 'center',
              image: this.subBarChart2,
            },
          ],
        },
        this.isDraft ? '' : {
          columns: [
            {
              margin: [0, 20, 0, 0],
              width: 500,
              height: 250,
              alignment: 'center',
              image: this.subBarChart3,
            },
          ],
        },
        this.isDraft ? '' : {
          columns: [
            {
              margin: [0, 20, 0, 0],
              width: 500,
              height: 250,
              pageBreak: 'after',
              alignment: 'center',
              image: this.subBarChart4,
            },
          ],
        },
        {
          table: {
            widths: [500],
            heights: [32, 232],
            body: [
              [
                {
                  text: this._translate.instant("notes") + ' :',
                  alignment: 'left',
                  fontSize: '12',
                  bold: false,
                  border: [true, true, true, false],
                  margin: [8, 8, 0, 0],
                },
              ],
              [
                {
                  text: ' ',
                  alignment: 'center',
                  fontSize: '12',
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
              ],
            ],
          },
          border: [true, true, true, true],
          margin: [0, 16, 0, 0],
        },
        earlyLateSkillsBody.length <= 0 ? '' : {
          table: {
            widths: [500],
            body: earlyLateSkillsBody
          },
          border: [true, true, true, true],
          margin: [0, 16, 0, 0],
        },

        {
          pageBreak: 'before',
          table: {
            dontBreakRows: true,
            widths: [72, 240, 44, 40, 40, 40, 40],
            body: rows,
          },
          layout: {
            hLineWidth: function (i, node) {
              if (i === 0 || i === node.table.body.length) {
                return 0;
              }
              return i === node.table.headerRows ? 2 : 0.5;
            },
            vLineWidth: function (i) {
              return 0;
            },
            hLineColor: function (i) {
              return i === 1 ? 'black' : '#757575';
            },
            paddingLeft: function (i) {
              return i === 0 ? 0 : 0;
            },
            paddingRight: function (i, node) {
              return i === node.table.widths.length - 1 ? 0 : 0;
            },
          },
        },
      ],
      defaultStyle: {
        font: 'BarlowSemiCondensed',
        fontSize: 10,
      },
      styles: {
        tableHeader: {
          fontSize: 8,
          color: '#757575',
          alignment: 'center',
          bold: true,
        },
        cat1Title: {
          fontSize: 16,
          alignment: 'left',
          bold: false,
        },
        cat2Title: {
          fontSize: 12,
          alignment: 'left',
          bold: false,
        },
        answer: {
          alignment: 'center',
          fontSize: 8,
          margin: 5,
        },
      },
    };

    pdfMake.fonts = {
      BarlowSemiCondensed: {
        normal: `https://db.onlinewebfonts.com/t/b34370616f8c4fa5d043a5e674b78a02.ttf`,
        bold: `https://db.onlinewebfonts.com/t/b7299979c1b5f55ee14ca767fd1290ab.ttf`
      },
    };

    (await pdfMake
      .createPdf(pdf))
      .download(pdf.info.title);
    this.pdfLoading = false;
  }
}
