import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { AppState, selectUserState } from 'src/app/core/core.state';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { setSubscription, setUser } from 'src/app/core/user/user.actions';
import { UserModel } from 'src/app/shared/models/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CancelSubComponent } from './cancel-sub/cancel-sub.component';

@Component({
    selector: 'app-param',
    templateUrl: './param.component.html',
    styleUrls: ['./param.component.scss'],
    standalone: false
})
export class ParamComponent implements OnInit, OnDestroy {
  userData: UserModel;
  isLoading = false;
  storeSubscription: Subscription;
  editUserform: FormGroup;
  isEditUserLoading = false;

  constructor(
    public translate: TranslateService,
    public router: Router,
    public dialog: MatDialog,
    private _store: Store<AppState>,
    private _translate: TranslateService,
    private _graphql: GraphqlService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;

    this._graphql.getCurrentUser()
      .pipe(
        take(1),
        finalize(() => this.isLoading = false)
      )
      .subscribe(({ data }: any) => {
        if (data.user) {
          this._store.dispatch(setUser({ user: data.user }))
        }
      })

    this.storeSubscription = this._store.pipe(
      select(selectUserState),
    ).subscribe(
      {
        next: user => {
          this.userData = user;
          this.editUserform = new FormGroup({
            firstname: new FormControl(this.userData.firstname, {
              validators: [Validators.required],
            }),
            lastname: new FormControl(this.userData.lastname, {
              validators: [Validators.required],
            }),
            email: new FormControl(this.userData.email, {
              validators: [Validators.required],
            }),
            phone: new FormControl(this.userData.phone, {
              validators: [],
            }),
          });
        },
        error: (error) => {
          this.sharedService.makeToast(
            `Une erreur est survenue: ${error}`,
            '',
            'danger'
          );
        }
      }
    )
  }

  onEditUser() {
    this.isEditUserLoading = true;
    this._graphql.editUser(
      this.editUserform.value.firstname,
      this.editUserform.value.lastname,
      this.editUserform.value.phone,
      this.editUserform.value.email,
    )
      .pipe(
        take(1),
        finalize(() => this.isEditUserLoading = false))
      .subscribe(
        {
          next: () => {
            this._store.dispatch(setUser({
              user: {
                firstname: this.editUserform.value.firstname,
                lastname: this.editUserform.value.lastname,
                phone: this.editUserform.value.phone,
                email: this.editUserform.value.email,
              }
            }));
            this.sharedService.makeToast(
              this._translate.instant('modificationsSaved'),
              '',
              'success'
            );
          },
          error: (error) => {
            this.sharedService.makeToast(
              this._translate.instant('errorOccured') + error,
              '',
              'danger'
            );
          }
        }
      )
  }

  toggleCancelSub() {
    const dialogRef = this.dialog.open(CancelSubComponent, {
      data: {
        subscription: this.userData.subscription,
      },
    });
    dialogRef.afterClosed().pipe(take(1))
      .subscribe(data => {
        if (data) {
          this._store.dispatch(setSubscription({
            subscription: {
              ...this.userData.subscription,
              cancelAtPeriodEnd: !this.userData.subscription.cancelAtPeriodEnd,
              cancelAt: this.userData.subscription.cancelAtPeriodEnd ? this.userData.subscription.currentPeriodEnd : null,
            }
          }))
        }
      })
  }

  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
  }
}
