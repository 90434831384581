import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import {
  MatDialogRef,
 MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Obs } from 'src/app/shared/models/obs.model';
import { finalize, map, take } from 'rxjs/operators';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/core.state';
import { selectValidatedObsIds } from 'src/app/core/record/record.selectors';
import {  Router } from '@angular/router';

@Component({
    templateUrl: './observations-list.component.html',
    styleUrls: ['./observations-list.component.scss'],
    standalone: false
})
export class ObservationsListComponent implements OnInit, OnDestroy {
  allObsSorted: Obs[] = [];

  isLoading = false;

  allObs: Obs[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ObservationsListComponent>,
    private _store: Store<AppState>,
    private _router: Router,
    private _graphql: GraphqlService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this._store.select(selectValidatedObsIds)
      .pipe(
        take(1),
        finalize(()=> this.isLoading = false)
      ).subscribe(ids => {
        this._graphql.getFinishedObservations(ids)
          .pipe(
            take(1),
            map(({ data }: any) => data.bulkObservations)
          )
          .subscribe(observations => this.allObs = [...observations])
      })
  }

  onViewObs(obsId: string) {
    this.dialogRef.close();
    this._router.navigateByUrl(`start/doc/${this.data.recordId}/observation/${obsId}`)
  }

  ngOnDestroy() {
  }
}
