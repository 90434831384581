import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './new-observation.component.html',
    styleUrls: ['./new-observation.component.scss'],
    standalone: false
})
export class NewObservationComponent implements OnInit{
  form: FormGroup;

  serializedDate = new FormControl(new Date().toISOString());

  get f() { return this.form.value; }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NewObservationComponent>,
    private _formBuilder: FormBuilder,
    ) {}
  ngOnInit(): void {
    this.form = this._formBuilder.group({
      signature: ['', [Validators.required, Validators.minLength(2)]],

    });
  }

  onStart() {
    this.dialogRef.close({
      signature: this.f.signature,
    });
  }
}
