<div class="content">
  <app-header></app-header>
  <ion-row>
        <ion-col style="
          text-align: center;
          height: 80vh;
          place-items: center;
          display: grid;
        " size="12">
      <h2>{{ "passwordReset" | translate | capitalizeFirstLetter }} </h2>
      <p [innerHTML]="'passwordValidationRules' | translate"></p>
      <div [formGroup]="changePasswordForm">
        <!-- Password field -->
        <mat-form-field appearance="outline" color="accent" style="width: 100%">
          <mat-label>{{ "newPassword" | translate | capitalizeFirstLetter }}</mat-label>
          <input matInput type="password" [formControlName]="'password'" />
        </mat-form-field>
        <br />
        <mat-form-field appearance="outline" color="accent" style="width: 100%">
          <mat-label>{{ "confirmPassword" | translate | capitalizeFirstLetter }}</mat-label>
          <input matInput type="password" formControlName="passwordConfirm" />
        </mat-form-field>
        @if (changePasswordForm.hasError('notSame') && f.passwordConfirm) {
          <p style="color: tomato;">{{ 'passwordError' | translate | capitalizeFirstLetter }}</p>
        }
        <button mat-raised-button color="accent"
          [disabled]="changePasswordForm.invalid"
          (click)="changePassword()"
                    submit style="
                min-width: 100%;
                min-height: 36px;
                border-radius: 0px;
                text-align: -webkit-center;
              ">
          @if (!isLoading) {
            <span>{{ "confirm" | translate | capitalizeFirstLetter }}</span>
          }
          @if (isLoading) {
            <mat-progress-spinner style="display: block;" [diameter]="24" [mode]="'indeterminate'" color="accent">
            </mat-progress-spinner>
          }
        </button>


      </div>
    </ion-col>
    <mat-divider> </mat-divider>
  </ion-row>
</div>