import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import periods from 'src/assets/json/periodsCategorized.json';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export interface PeriodElement {
  category: string;
  author?: string;
  periodGroupCode: string;
  periodGroupName: string;
  periodName: string;
  startMonth: number;
  endMonth: number;
  definition?: string;
}

export interface Period {
  period: PeriodElement[];
  processName: string;
}

export interface Category {
  category: Period[];
  nom: string;
  author?: string;
}

@Component({
    selector: 'app-ref-theo',
    templateUrl: './ref-theo.component.html',
    styleUrls: ['./ref-theo.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RefTheoComponent implements OnInit {
  categories: Category[] = periods;
  displayedColumns: string[] = ['Période', 'Début', 'Fin'];
  expandedElement: PeriodElement | null;
  constructor(private route: ActivatedRoute, public router: Router, private _translate: TranslateService) {}

  ngOnInit(): void {

  }
}
