import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize, take } from 'rxjs/operators';

@Component({
    templateUrl: './reset-pwd.component.html',
    styleUrls: ['./reset-pwd.component.scss'],
    standalone: false
})
export class ResetPwdComponent {

  isLoading = false;
  form: FormGroup = new FormGroup({
    email: new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])
    ),
  });
  constructor(
    public dialogRef: MatDialogRef<ResetPwdComponent>,
    private _http: HttpClient
  ) { }

  resetPwd() {
    this.isLoading = true;
    this._http.post("password_reset", { email: this.form.value.email }, { responseType: 'text' })
      .pipe(
        take(1),
        finalize(() => this.isLoading = false))
      .subscribe(
        {
          next: res => {
            this.dialogRef.close({
              success: true,
              message: ""
            })
          },
          error: (error) => {
            this.dialogRef.close({
              success: false,
              message: error.error
            })
          }
        }
      )
  }
}
