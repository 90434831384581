@if (isLoading || filteredQues == undefined) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}
@if (answers) {
  <div>
  <div style="
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    padding-left: 16px;
  ">
    <div style="
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
    ">
        <mat-slide-toggle [checked]="displayPreviousAnswers" (change)="toggleDisplayPreviousAnswers($event)">{{
        "displayPreviousAnswers" | translate | capitalizeFirstLetter}}</mat-slide-toggle>
        <button mat-button (click)="accordion.openAll()" style="border-radius: 0px">
          {{ "unfoldMore" | translate | capitalizeFirstLetter}} <mat-icon>unfold_more</mat-icon>
        </button>
        <button mat-button (click)="accordion.closeAll()" style="border-radius: 0px">
          {{ "unfoldLess" | translate | capitalizeFirstLetter}} <mat-icon>unfold_less</mat-icon>
        </button>
      </div>
    </div>
    <div style="text-align: -webkit-center">
      <mat-accordion class="example-headers-align" multi>
        @for (element of filteredQues; track element; let i = $index) {
          <div>
            <!--that's a very ugly condition; sorry. The format for the title isn't helping... -->
            @if (element.length > 0 && (i>0 && filteredQues[i-1][0].category1NameLong != element[0].category1NameLong) || (i==0)) {
              <h1
                >
                {{ element[0]?.category1NameLong | translate | capitalizeFirstLetter }}
              </h1>
            }
            @if (element?.length > 0) {
<mat-expansion-panel [id]="(element.length > 0 && (i>0 && filteredQues[i-1][0].category1NameLong != element[0].category1NameLong) || (i==0)) 
          ?
          element[0].category1Rk
          : 
          ''
          " class="mat-elevation-z0" style="
          border-radius: 0px;
          border-width: 1px;
          border-style: solid;
          border-color: #eeeeee;
        " expanded>
                <mat-expansion-panel-header>
                  @if (element.length > 0) {
                    <mat-panel-title>
              <div [ngClass]="{
                catABox: element[0].category1Rk === 'A',
                catBBox: element[0].category1Rk === 'B',
                catCBox: element[0].category1Rk === 'C',
                catDBox: element[0].category1Rk === 'D',
                catEBox: element[0].category1Rk === 'E'
              }"></div>
                      {{ ("QUESTIONS."+element[0].category1Name+'_'+element[0].category2Rk) | translate }}
                    </mat-panel-title>
                  }
                  <mat-panel-description>
                    <!-- {{element[0]?.category_2_name}} -->
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <!-- <p> {{element[0]?.category_2_name}}</p> -->
                <table mat-table [dataSource]="element" multiTemplateDataRows class="mat-elevation-z0">
                  <ng-container matColumnDef="Options">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell style="padding-left: 8px" *matCellDef="let element">
                      <div>
                        <button mat-button (click)="onHistoryView(element.answerCode)"
                          [disabled]="!answers[element.answerCode]?.level">
                          <mat-icon>history</mat-icon>
                        </button>
                      </div>
                      <!-- <div *ngIf="element.type != 'info'">
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="menuDesQuestions"
                        aria-label="Example icon button with a vertical three dot icon"
                        >
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menuDesQuestions="matMenu">
                        <button
                          mat-menu-item
                          (click)="onHistoryView(element.answerCode)"
                          >
                          <mat-icon>history</mat-icon>
                          <span>Voir l'historique des réponses</span>
                        </button>
                      </mat-menu>
                    </div> -->
                  </td>
                </ng-container>
                <ng-container matColumnDef="Age">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell style="width: 80px" *matCellDef="let element">
                    @if (element.direction === 'starting') {
                      <div #tooltip="matTooltip"
                        [matTooltip]="_translate.instant('startingAgeTooltip')" matTooltipPosition="above"
                        matTooltipHideDelay="0" matTooltipClass="question-tooltip-box">
                  <div [ngClass]="{
                    'ref-age-box-wrapper': element.direction === 'starting',
                      'ref-age-box-wrapper-fullfilled':
                      element.endMonth > age &&
                      answers[element.answerCode]?.level == 5
                  }">
                          <div class="ref-age-box">
                            <span style="font-size: 0.7rem; font-weight: 400">{{
                              element.startMonth | age | async
                            }}</span>
                          </div>
                        </div>
                      </div>
                    }
                    @if (element.direction === 'until') {
                      <div #tooltip="matTooltip"
                        [matTooltip]="_translate.instant('endingAgeTooltip')" matTooltipPosition="above"
                        matTooltipHideDelay="0" matTooltipClass="question-tooltip-box">
                  <div [ngClass]="{
                    'ref-age-box-wrapper2':true,
                    'ref-age-box-wrapper3':
                      element.direction === 'until' &&
                      element.endMonth < age &&
                      answers[element.answerCode]?.level &&
                      answers[element.answerCode].level !== 5,
                      'ref-age-box-wrapper4':
                      element.endMonth > age &&
                      answers[element.answerCode]?.level == 5
                  }">
                          <div class="ref-age-box">
                            <span style="font-size: 0.7rem; font-weight: 400">{{
                              element.endMonth | age | async
                            }}</span>
                          </div>
                        </div>
                      </div>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="Question">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element" style="min-width: 120px; width: 60%">
                    <div class="question-box">
                      <div>
                        {{ "QUESTIONS."+element.answerCode + '_name' |translate | capitalizeFirstLetter }}
                      </div>
                      @if (element.description !== null) {
                        <div class="material-icons-outlined">
                          <div #tooltip="matTooltip"
                            matTooltip="{{ _translate.instant('QUESTIONS.'+element.answerCode + '_description') }}"
                            matTooltipPosition="right" matTooltipHideDelay="500" matTooltipClass="question-tooltip-box"
                            class="question-tooltip">
                            <mat-icon>info_outline</mat-icon>
                          </div>
                        </div>
                      }
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Réflexion">
                  <th style="padding: 0;" mat-header-cell *matHeaderCellDef>
                    <div #tooltip="matTooltip" class="question-tooltip" [matTooltip]="_translate.instant('unconcernedTooltip')"
                      matTooltipPosition="above" matTooltipHideDelay="0" matTooltipClass="question-tooltip-box">
                      {{ "unconcerned" | translate | capitalizeFirstLetter}}
                    </div>
                  </th>
              <td [ngClass]="{
                obsModeCatA: element.category1Rk === 'A',
                obsModeCatB: element.category1Rk === 'B',
                obsModeCatC: element.category1Rk === 'C',
                obsModeCatD: element.category1Rk === 'D',
                obsModeCatE: element.category1Rk === 'E'
              }" style="width: 80px; text-align: center" class="radioButtonCell" mat-cell *matCellDef="let element">
                    <div #tooltip="matTooltip" [matTooltip]="_translate.instant('unconcerned')" matTooltipPosition="above"
                      matTooltipShowDelay="300" matTooltipHideDelay="0" matTooltipClass="question-tooltip-box">
                      @if (displayPreviousAnswers && previousAnswers[element.answerCode]?.level==1) {
                        <div class="prevAnswerUnconcerned"
                        ></div>
                      }
                      @if (!isDraft && answers[element.answerCode]?.level === 1) {
                        <div>
                          <!-- {{ element.date.toDate() | date: "shortDate" }} -->
                    <div [ngClass]="{
                      catA: element.category1Rk === 'A',
                      catB: element.category1Rk === 'B',
                      catC: element.category1Rk === 'C',
                      catD: element.category1Rk === 'D',
                      catE: element.category1Rk === 'E'
                    }"></div>
                        </div>
                      }
                      @if (!isDraft && answers[element.answerCode]?.level !== 1) {
                        <div>
                          <div class="step"></div>
                        </div>
                      }
                      @if (isDraft) {
                        <mat-radio-button [disableRipple]="true" #radio
                          (click)="setElementLevel(element, 1, radio, $event)" [name]="element.answerCode" value="1"
                          [checked]="answers[element.answerCode]?.level === 1">
                        </mat-radio-button>
                      }
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Non-accès">
                  <th style="text-align-last: center;padding: 0;" mat-header-cell *matHeaderCellDef>
                    <div #tooltip="matTooltip" class="question-tooltip" [matTooltip]="_translate.instant('nonAccessTooltip')"
                      matTooltipPosition="above" matTooltipHideDelay="0" matTooltipClass="question-tooltip-box">
                      {{ "nonAccess" | translate | capitalizeFirstLetter}}
                    </div>
                  </th>
              <td [ngClass]="{
                obsModeCatA: element.category1Rk === 'A',
                obsModeCatB: element.category1Rk === 'B',
                obsModeCatC: element.category1Rk === 'C',
                obsModeCatD: element.category1Rk === 'D',
                obsModeCatE: element.category1Rk === 'E'
              }" style="width: 80px; text-align: center;" class="radioButtonCell" mat-cell *matCellDef="let element">
                    <div #tooltip="matTooltip" [matTooltip]="_translate.instant('nonAccess')" matTooltipPosition="above"
                      matTooltipShowDelay="300" matTooltipHideDelay="0" matTooltipClass="question-tooltip-box">
                      @if (displayPreviousAnswers && previousAnswers[element.answerCode]?.level==2) {
                        <div class="prevAnswerNonAccess"
                        ></div>
                      }
                      @if (!isDraft && answers[element.answerCode]?.level === 2) {
                        <div>
                          <!-- {{ element.date.toDate() | date: "shortDate" }} -->
                    <div [ngClass]="{
                      catA: element.category1Rk === 'A',
                      catB: element.category1Rk === 'B',
                      catC: element.category1Rk === 'C',
                      catD: element.category1Rk === 'D',
                      catE: element.category1Rk === 'E'
                    }"></div>
                        </div>
                      }
                      @if (!isDraft && answers[element.answerCode]?.level !== 2) {
                        <div>
                          <div class="step"></div>
                        </div>
                      }
                      @if (isDraft) {
                        <mat-radio-button [disableRipple]="true" #radio
                          (click)="setElementLevel(element, 2, radio, $event)" [name]="element.answerCode" value="2"
                          [checked]="answers[element.answerCode]?.level === 2">
                        </mat-radio-button>
                      }
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="S'exerce">
                  <th style="text-align-last: center;padding: 0;" mat-header-cell *matHeaderCellDef>
                    <div #tooltip="matTooltip" class="question-tooltip" [matTooltip]="_translate.instant('exercisingTooltip')"
                      matTooltipPosition="above" matTooltipHideDelay="0" matTooltipClass="question-tooltip-box">
                      {{ "exercising" | translate | capitalizeFirstLetter}}
                    </div>
                  </th>
              <td [ngClass]="{
                obsModeCatA: element.category1Rk === 'A',
                obsModeCatB: element.category1Rk === 'B',
                obsModeCatC: element.category1Rk === 'C',
                obsModeCatD: element.category1Rk === 'D',
                obsModeCatE: element.category1Rk === 'E'
              }" style="width: 80px; text-align: center;" class="radioButtonCell" mat-cell *matCellDef="let element">
                    <div #tooltip="matTooltip" [matTooltip]="_translate.instant('exercising')" matTooltipPosition="above"
                      matTooltipShowDelay="300" matTooltipHideDelay="0" matTooltipClass="question-tooltip-box">
                      @if (displayPreviousAnswers && previousAnswers[element.answerCode]?.level==3) {
                        <div class="prevAnswerExercising"
                        ></div>
                      }
                      @if (!isDraft && answers[element.answerCode]?.level === 3) {
                        <div>
                          <!-- {{ element.date.toDate() | date: "shortDate" }} -->
                    <div [ngClass]="{
                      catA: element.category1Rk === 'A',
                      catB: element.category1Rk === 'B',
                      catC: element.category1Rk === 'C',
                      catD: element.category1Rk === 'D',
                      catE: element.category1Rk === 'E'
                    }"></div>
                        </div>
                      }
                      @if (!isDraft && answers[element.answerCode]?.level !== 3) {
                        <div>
                          <div class="step"></div>
                        </div>
                      }
                      @if (isDraft) {
                        <mat-radio-button [disableRipple]="true" #radio
                          (click)="setElementLevel(element, 3, radio, $event)" [name]="element.answerCode" value="3"
                          [checked]="answers[element.answerCode]?.level === 3">
                        </mat-radio-button>
                      }
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Intègre">
                  <th style="text-align-last: center;padding: 0;" mat-header-cell *matHeaderCellDef>
                    <div #tooltip="matTooltip" class="question-tooltip" [matTooltip]="_translate.instant('integratingTooltip')"
                      matTooltipPosition="above" matTooltipHideDelay="0" matTooltipClass="question-tooltip-box">
                      {{ "integrating" | translate | capitalizeFirstLetter}}
                    </div>
                  </th>
              <td [ngClass]="{
                obsModeCatA: element.category1Rk === 'A',
                obsModeCatB: element.category1Rk === 'B',
                obsModeCatC: element.category1Rk === 'C',
                obsModeCatD: element.category1Rk === 'D',
                obsModeCatE: element.category1Rk === 'E'
              }" style="width: 80px; text-align: center" class="radioButtonCell" mat-cell *matCellDef="let element">
                    <div #tooltip="matTooltip" [matTooltip]="_translate.instant('integrating')" matTooltipPosition="above"
                      matTooltipShowDelay="300" matTooltipHideDelay="0" matTooltipClass="question-tooltip-box">
                      @if (displayPreviousAnswers && previousAnswers[element.answerCode]?.level==4) {
                        <div class="prevAnswerIntegrating"
                        ></div>
                      }
                      @if (!isDraft && answers[element.answerCode]?.level === 4) {
                        <div>
                          <!-- {{ element.date.toDate() | date: "shortDate" }} -->
                    <div [ngClass]="{
                      catA: element.category1Rk === 'A',
                      catB: element.category1Rk === 'B',
                      catC: element.category1Rk === 'C',
                      catD: element.category1Rk === 'D',
                      catE: element.category1Rk === 'E'
                    }"></div>
                        </div>
                      }
                      @if (!isDraft && answers[element.answerCode]?.level !== 4) {
                        <div>
                          <div class="step"></div>
                        </div>
                      }
                      @if (isDraft) {
                        <mat-radio-button [disableRipple]="true" #radio
                          (click)="setElementLevel(element, 4, radio, $event)" [name]="element.answerCode" value="4"
                          [checked]="answers[element.answerCode]?.level === 4">
                        </mat-radio-button>
                      }
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Assimilé">
                  <th style="text-align-last: center;padding: 0;" mat-header-cell *matHeaderCellDef>
                    <div #tooltip="matTooltip" class="question-tooltip" [matTooltip]="_translate.instant('assimilatedTooltip')"
                      matTooltipPosition="above" matTooltipHideDelay="0" matTooltipClass="question-tooltip-box">
                      {{ "assimilated" | translate | capitalizeFirstLetter}}
                    </div>
                  </th>
              <td [ngClass]="{
                obsModeCatA: element.category1Rk === 'A',
                obsModeCatB: element.category1Rk === 'B',
                obsModeCatC: element.category1Rk === 'C',
                obsModeCatD: element.category1Rk === 'D',
                obsModeCatE: element.category1Rk === 'E'
              }" style="width: 80px; text-align: center" class="radioButtonCell" mat-cell *matCellDef="let element">
                    <div #tooltip="matTooltip" [matTooltip]="_translate.instant('assimilated')" matTooltipPosition="above"
                      matTooltipShowDelay="300" matTooltipHideDelay="0" matTooltipClass="question-tooltip-box">
                      @if (displayPreviousAnswers && previousAnswers[element.answerCode]?.level==5) {
                        <div class="prevAnswerAssimilated"
                        ></div>
                      }
                      @if (!isDraft && answers[element.answerCode]?.level === 5) {
                        <div>
                          <!-- {{ element.date.toDate() | date: "shortDate" }} -->
                    <div [ngClass]="{
                      catA: element.category1Rk === 'A',
                      catB: element.category1Rk === 'B',
                      catC: element.category1Rk === 'C',
                      catD: element.category1Rk === 'D',
                      catE: element.category1Rk === 'E'
                    }"></div>
                        </div>
                      }
                      @if (!isDraft && answers[element.answerCode]?.level !== 5) {
                        <div>
                          <div class="step"></div>
                        </div>
                      }
                      @if (isDraft) {
                        <mat-radio-button [disableRipple]="true" #radio
                          (click)="setElementLevel(element, 5, radio, $event)" [name]="element.answerCode" value="5"
                          [checked]="answers[element.answerCode]?.level === 5">
                        </mat-radio-button>
                      }
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay"></tr>
              </table>
            </mat-expansion-panel>
          }
          @if (element?.length > 0) {
            <br />
          }
        </div>
      }
    </mat-accordion>
  </div>
</div>
}