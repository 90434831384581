<ion-content class="has-header" overflow-scroll="true">
  <ion-row>
    <ion-col size="12" size-lg="10" offset-lg="1" size-xl="10" offset-xl="1">
      <app-header title="references" secondTitle="theoreticalReferences" isBack="true" backTitle="back" backTo="ref"
      show="true"></app-header>
      <div>
        <mat-tab-group mat-align-tabs="center" color="accent" backgroundColor="white">
          @for (cat of categories; track cat) {
            <mat-tab [label]="_translate.instant(cat.nom).toUpperCase()">
              <ion-row style="margin-top: 32px">
                <ion-col style="text-align: center">
                  @if (cat.author) {
                    <h2>{{ "by" | translate | capitalizeFirstLetter}} {{ cat.author }}</h2>
                  }
                </ion-col>
              </ion-row>
              @for (period of cat.category; track period) {
                <div>
                  <ion-row style="margin-top: 32px">
                    <ion-col style="text-align: center">
                      <h3 style="margin-bottom: 0px">{{ period.processName | translate | capitalizeFirstLetter}}</h3>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size-md="8" offset-md="2">
                      <table mat-table [dataSource]="period.period" multiTemplateDataRows class="mat-elevation-z0">
                        <ng-container matColumnDef="Période">
                          <th style="color: black" mat-header-cell *matHeaderCellDef></th>
                      <td style="width: 100%; min-width: 240px" mat-cell *matCellDef="let element" (click)="
                          expandedElement =
                            expandedElement === element ? null : element
                        ">
                            <div style="display: flex; align-items: center">
                              @if (expandedElement === element) {
                                <mat-icon color="accent" style="padding-bottom: 4px">
                                arrow_drop_down</mat-icon>
                              }
                              @if (expandedElement !== element) {
                                <mat-icon color="accent" style="padding-bottom: 4px">
                                arrow_right</mat-icon>
                              }
                              {{ 'REFS.'+element.periodCode + '_periodName' | translate }}
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Début">
                          <th style="color: black" mat-header-cell *matHeaderCellDef>
                            {{ "begining" | translate | capitalizeFirstLetter }}
                          </th>
                      <td style="min-width: 120px" mat-cell *matCellDef="let element" (click)="
                          expandedElement =
                            expandedElement === element ? null : element
                        ">
                            {{ element.startMonth | age | async }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Fin" justify="end">
                          <th style="color: black" mat-header-cell *matHeaderCellDef>
                            {{ "end" | translate | capitalizeFirstLetter }}
                          </th>
                      <td style="min-width: 120px" mat-cell *matCellDef="let element" (click)="
                          expandedElement =
                            expandedElement === element ? null : element
                        ">
                            {{ element.endMonth | age | async }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="expandedDetail">
                          <td style="border-bottom-width: 0" mat-cell *matCellDef="let element">
                        <div class="example-element-detail" [@detailExpand]="
                            element === expandedElement
                              ? 'expanded'
                              : 'collapsed'
                          ">
                              <div class="example-element-text">
                                {{ 'REFS.'+element.periodCode + '_definition' | translate }}
                              </div>
                            </div>
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns" class="example-element-row"
                        [class.example-expanded-row]="expandedElement === element"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                      </table>
                    </ion-col>
                  </ion-row>
                </div>
              }
            </mat-tab>
          }
        </mat-tab-group>
      </div>
    </ion-col>
  </ion-row>
</ion-content>