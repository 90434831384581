import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
    selector: 'app-edit-password',
    templateUrl: './edit-password.component.html',
    styleUrls: ['./edit-password.component.scss'],
    standalone: false
})
export class EditPasswordComponent implements OnInit {
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _translate: TranslateService,
    private sharedService: SharedService,
    private loadingCtrl: LoadingController,
    private _graphql: GraphqlService,
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup(
      {
        newPassword: new FormControl("", {
          validators: [Validators.required],
        }),
        confirmPassword: new FormControl("", {
          validators: [Validators.required],
        }),
      },
      {
        validators: (control) => {
          if (control.value.newPassword !== control.value.confirmPassword) {
            control.get('confirmPassword').setErrors({ notSame: true });
          }
          return null;
        },
      }
    );
  }

  onConfirm() {
    this.loadingCtrl
      .create({
        message: this._translate.instant('modifying'),
      })
      .then((loadingEl) => {
        loadingEl.present();
        this._graphql.resetPassword(this.form.value.newPassword)
          .pipe(take(1))
          .subscribe(
            {
              next: () => {
                this.sharedService.makeToast(
                  this._translate.instant('pwdChangedSuccess'),
                  '',
                  'success'
                );
                loadingEl.dismiss();
              },
              error: (error) => {
                loadingEl.dismiss();
                this.sharedService.makeToast(
                  this._translate.instant("errorOccured:" + error),
                  '',
                  'danger'
                );
              }
            }
          )
      });
  }
}
