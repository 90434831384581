<form [formGroup]="signInForm">
  <!-- Email field -->
  <mat-form-field appearance="outline" color="accent" style="width: 100%">
    <mat-label>{{ "email" | translate | capitalizeFirstLetter }}</mat-label>
    <input id="email" matInput [formControlName]="'email'" />
  </mat-form-field>
  <br />
  <!-- Password field -->
  <mat-form-field appearance="outline" color="accent" style="width: 100%">
    <mat-label>{{ "password" | translate | capitalizeFirstLetter }}</mat-label>
    <input matInput type="password" [formControlName]="'password'" />
    <!-- <button
    mat-icon-button
    matSuffix
    (click)="hide = !hide"
    [attr.aria-label]="'Hide password'"
    [attr.aria-pressed]="hide"
    >
    <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
  </button> -->
</mat-form-field>

<br />
<button
  mat-flat-button
  color="primary"
  [disabled]="signInForm.disabled"
  (click)="signInWithEmail()"
  type="submit"
    style="
      min-width: 100%;
      min-height: 36px;
      border-radius: 0px;
      text-align: -webkit-center;
    "
  >
  @if (!isLoading) {
    <span> {{ "connexion" | translate | capitalizeFirstLetter }} </span>
  }
  @if (isLoading) {
    <mat-progress-spinner
      [diameter]="24"
      style="display: block;"
      [mode]="'indeterminate'"
      color="accent"
    ></mat-progress-spinner>
  }
</button>

</form>
