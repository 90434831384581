import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Pipe({
    name: 'age',
    standalone: false
})
export class AgePipe implements PipeTransform, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject<void>();

  private month: string;
  private year: string;
  private years: string;
  constructor(
    private _translate: TranslateService
  ) {
    this._translate.get("modifyPassword").subscribe();
    this._translate.get("month").pipe(
      takeUntil(this.componentDestroyed$),
    ).subscribe(val => this.month = val)
    this._translate.get("year").pipe(
      takeUntil(this.componentDestroyed$),
    ).subscribe(val => this.year = val)
    this._translate.get("years").pipe(
      takeUntil(this.componentDestroyed$),
    ).subscribe(val => this.years = val)
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next()
    this.componentDestroyed$.complete()
  }


  async changeFormat(value) {
    const ageYear = Math.floor(value / 12);
    const ageMonth = value - ageYear * 12;
    if (value < 12) {
      const ageString = `${ageMonth} ${this.month}`;
      return ageString;
    }
    if (value === 12) {
      const ageString = `${ageYear} ${this.year}`;
      return ageString;
    }
    if (value < 24) {
      const ageString = `${ageYear} ${this.year}, ${ageMonth} ${this.month}`;
      return ageString;
    }
    if (value >= 24) {
      if (ageMonth === 0) {
        const ageString = `${ageYear} ${this.years}`;
        return ageString;
      }
      const ageString = `${ageYear} ${this.years}, ${ageMonth} ${this.month}`;
      return ageString;
    }
  }

  transform(value: any, ...args: unknown[]) {
    if (value < 0) {
      let x = '-' + this.changeFormat(value * -1);
      return x;
    } else {
      return this.changeFormat(value);
    }
  }
}
