import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-tos',
    templateUrl: './tos.component.html',
    styleUrls: ['./tos.component.scss'],
    standalone: false
})
export class TosComponent {
  constructor() {}
}
