<div style="padding: 24px;">
  <ion-row class="sticky">
    <ion-col size="2"></ion-col>
    <ion-col size="8">
      <h1 style="text-align: center">{{ "finishedObs" | translate | capitalizeFirstLetter }}</h1>
    </ion-col>
    <ion-col style="text-align: end" size="2">
      <ion-button mat-dialog-close fill="clear">
        <mat-icon>close</mat-icon>
      </ion-button>
    </ion-col>
  </ion-row>
  <mat-dialog-content>
    <ion-row>
      <ion-col size-md="8" offset-md="2">
        <ion-list>
          <ion-item>
            <h2>{{ "list" | translate | capitalizeFirstLetter }}</h2>
          </ion-item>
          @if (isLoading) {
            <ion-progress-bar type="indeterminate"></ion-progress-bar>
          }
          @for (o of allObs; track o) {
            <ion-item>
              {{ o.date | date: 'longDate' }}
              <ion-buttons slot="end">
                <button (click)="onViewObs(o.id)" mat-icon-button>
                  <mat-icon>visibility</mat-icon>
                </button>
              </ion-buttons>
            </ion-item>
          }
        </ion-list>
      </ion-col>
    </ion-row>
  </mat-dialog-content>
</div>