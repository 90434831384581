import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { finalize, map, take } from 'rxjs/operators';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { Record } from 'src/app/shared/models/record.model';
import { PaymentStatus, UserModel } from 'src/app/shared/models/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
    standalone: false
})
export class UserDetailsComponent implements OnInit {

  trialUsers: UserModel[] = [];
  normalUsers: UserModel[] = [];
  suspendedUsers: UserModel[] = [];
  trialExpiredUsers: UserModel[] = [];
  selectedUser: UserModel;
  editAddressForm: FormGroup;
  newEmail = '';
  isPageLoading = false;
  isUserLoading = false;
  isChangeAddressLoading = false;
  isChangeEmailLoading = false;
  isToggleUserPaymentStatusLoading = false;
  istoggleI18NLoading = false;
  isDeleteRecordLoading = false;
  loadingRecords = false;
  editAddress = false;
  editEmail = false;
  newPassword = '';
  editPassword = false;
  editPasswordLoading = false;
  userRecords = [];

  get addressForm() { return this.editAddressForm.value; }

  todo = ['Get to work', 'Pick up groceries', 'Go home', 'Fall asleep'];

  done = ['Get up', 'Brush teeth', 'Take a shower', 'Check e-mail', 'Walk dog'];

  drop(event: CdkDragDrop<UserModel[]>) {

    if (event.previousContainer !== event.container) {
      this.isToggleUserPaymentStatusLoading = true;
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      const draggedUser = event.container.data[event.currentIndex];
      this._graphql.changeUserPaymentStatus(draggedUser.id, event.container.id as PaymentStatus)
        .pipe(
          take(1),
          finalize(() => this.isToggleUserPaymentStatusLoading = false)
        ).subscribe({
          next: () => { },
          error: (error) => alert(error)
        });
    }
  }

  constructor(
    private _sharedService: SharedService,
    private _formBuilder: FormBuilder,
    private _graphql: GraphqlService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.editAddressForm = this._formBuilder.group({
      street: ['', [Validators.required, Validators.maxLength(100)]],
      number: ['', [Validators.required, Validators.maxLength(5)]],
      zip: ['', [Validators.required, Validators.maxLength(10)]],
      city: ['', [Validators.required, Validators.maxLength(50)]],
    });
    this.isPageLoading = true;
    this._graphql.getUsers()
      .pipe(
        take(1),
        map(({ data }: any) => data.users),
        finalize(() => this.isPageLoading = false)
      ).subscribe((users: UserModel[]) => {
        this.trialUsers = users.filter(u => u.paymentStatus == "trial");
        this.trialExpiredUsers = users.filter(u => u.paymentStatus == "trialExpired");
        this.normalUsers = users.filter(u => u.paymentStatus == "normal");
        this.suspendedUsers = users.filter(u => u.paymentStatus == "suspended").sort((a, b) => b.dateCreated.localeCompare(a.dateCreated));

      })
  }


  changeI18N(id: string, checked: boolean) {
    this.istoggleI18NLoading = true;
    this.selectedUser = { ...this.selectedUser, hasI18n: checked };
    this._graphql.toggleI18N(id).pipe(
      take(1),
      finalize(() => this.istoggleI18NLoading = false))
      .subscribe({
        next: () => { },
        error: error => alert(error)
      })
  }

  selectUser(id: number) {
    this.isUserLoading = true;
    this._graphql.getUserForAdmin(id).pipe(
      take(1),
      map(({ data }: any) => data.user),
      finalize(() => this.isUserLoading = false)
    ).subscribe({
      next: user => {
        this.selectedUser = { ...user };
        if (this.selectedUser.address) {
          this.editAddressForm.setValue({
            street: this.selectedUser.address.street,
            number: this.selectedUser.address.number,
            zip: this.selectedUser.address.zip,
            city: this.selectedUser.address.city,
          })
        }
        this.loadingRecords = true;
        this._graphql.getAllRecords(this.selectedUser.id)
          .pipe(
            take(1),
            map(({ data }: any) => data.allRecords),
            finalize(() => this.loadingRecords = false)
          ).subscribe(records => this.userRecords = records)
      },
      error: err => alert(err)
    })

  }

  changeAddress() {
    this.isChangeAddressLoading = true;
    this._graphql.changeAddress(this.selectedUser.id, this.addressForm)
      .pipe(
        take(1),
        finalize(() => this.isChangeAddressLoading = false)
      ).subscribe(address => {
        this._sharedService.makeToast("Adresse mise à jour!", '', 'success');
        this.selectedUser = { ...this.selectedUser, address: this.addressForm };
        this.editAddress = false;
      }
      )
  }

  changeEmail() {
    this.isChangeEmailLoading = true;
    this._graphql.changeEmail(this.selectedUser.id, this.newEmail)
      .pipe(
        take(1),
        finalize(() => this.isChangeEmailLoading = false)
      ).subscribe(email => {
        this._sharedService.makeToast("Email mise à jour!", '', 'success');
        this.selectedUser = { ...this.selectedUser, email: this.newEmail };
        this.editEmail = false;
      }
      )
  }

  changePassword() {
    this.editPasswordLoading = true;
    this._graphql.changePassword(this.selectedUser.id, this.newPassword)
      .pipe(
        take(1),
        finalize(() => this.editPasswordLoading = false)
      ).subscribe(_ => {
        this._sharedService.makeToast("Mot de passe mise à jour!", '', 'success');
        this.editPassword = false;
      }
      )
  }

  deleteRecord(record: Record) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: `Supprimer le dossier n°${record.recordNumber} de ${this.selectedUser.email}?`,
        learningEarly: [],
        learningLate: [],
        msg: `Attention Nadège, on ne pourra pas revenir en arrière!`,
      },
    })
      .afterClosed()
      .pipe(
        take(1)
      ).subscribe(result => {
        if (result.data) {
          this.isDeleteRecordLoading = true;
          this._graphql.deleteRecord(record.id)
            .pipe(
              take(1),
              finalize(() => this.isDeleteRecordLoading = false))
            .subscribe(() => {
              this.userRecords = this.userRecords.filter(rec => rec.id != record.id)
            })
        }
      });
  }
}
