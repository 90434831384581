import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Dumbbell from 'highcharts/modules/dumbbell';
import Lollipop from 'highcharts/modules/lollipop';

More(Highcharts);
Dumbbell(Highcharts);
Lollipop(Highcharts);

@Component({
    selector: 'app-record-by-year-chart',
    templateUrl: './record-by-year-chart.component.html',
    styleUrls: ['./record-by-year-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class RecordByYearChartComponent implements OnInit {

  @Input() data: { year: number, percentage: number }[]

  Highcharts: typeof Highcharts = Highcharts; // required

  chartRef: Highcharts.Chart;

  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'

  chartOptions: Highcharts.Options;

  chartCallback: Highcharts.ChartCallbackFunction = function (chart) {
    setTimeout(() => {
      chart.reflow();
    }, 800);
  }; // optional function, defaults to null

  updateFlag: boolean = false; // optional boolean

  bijectionFlag: boolean = true; // optional boolean, defaults to false

  constructor() { }

  ngOnInit() {

    this.chartOptions = {
      colors: ['#fe8dbe','#FD3F92',"#ea5294", "#ff278f","#fa036f"],
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
      },
      tooltip: {
        headerFormat: '<b>{point.name}</b><br/>',
        pointFormat: '{point.y}%',
      },
      series: [
        {
          type: 'pie',
          size: '80%',
          innerSize: '78%',
          showInLegend: true,
          data: this.data.map(elem => ([elem.year,elem.percentage])),
        },
      ],
      credits: {
        enabled: false,
      },
    };
  }

}


