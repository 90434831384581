import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UserModel } from 'src/app/shared/models/user.model';
import { SharedService } from '../../services/shared.service';
import { MenuController } from '@ionic/angular';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState, selectUserState } from 'src/app/core/core.state';
import { authLogout } from 'src/app/core/auth/auth.actions';
import { removeUser } from 'src/app/core/user/user.actions';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() backTo: string;

  @Input() show: boolean;

  @Input() title: string;

  @Input() subTitle: string;

  @Input() secondTitle: string;

  @Input() backTitle: string;

  @Input() isBack: boolean;

  isLoading = false;

  userData: UserModel;

  name: string;

  sub: Subscription;

  isSmallScreen: boolean;

  currentLang: string;

  assetsPath = environment.assetspath;

  constructor(
    public translate: TranslateService,
    private _location: Location,
    private _store: Store<AppState>,
    private _cookies: CookieService,
    public sharedService: SharedService,
    private menuCtrl: MenuController
  ) {}

  get fullname(){
    return `${this.userData?.firstname} ${this.userData?.lastname}`
  }

  setLanguage(lang: string){
    this._cookies.set('lang', lang);
    this.translate.use(lang);
    // window.location.reload();
  }

  ngOnInit(): void {
    this.sub = this._store.select(selectUserState).subscribe(userState=> this.userData=userState)
  }

  openMenu() {
    this.menuCtrl.enable(true, 'first');
    this.menuCtrl.open('first');
  }

  return() {
    this._location.back();
  }

  logOut() {
    this._store.dispatch(authLogout());
    this._store.dispatch(removeUser());
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
