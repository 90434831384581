import {
  ChangeDetectionStrategy, Component,

  Input,


  OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'app-assimilation-chart',
    templateUrl: './assimilation-chart.component.html',
    styleUrls: ['./assimilation-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class AssimilationChartComponent implements OnInit {

  @Input() data: Array<Array<any>>;

  dataWithZeros: Array<Array<any>> = [];

  Highcharts: typeof Highcharts = Highcharts; // required
  chartRef: Highcharts.Chart;
  lineChartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chartRef = chart;
    //ugly cheat, but fixes the chart layout problem
    window.setTimeout(function() { chart.reflow(); },100);
  }


  chartOptions: Highcharts.Options = {};

  constructor(private _translate: TranslateService,
    public dialog: MatDialog) { }

  refreshChart() {

    this.chartRef.series[4].update({name:this._translate.instant("assimilated"), type: "spline"});
    this.chartRef.series[3].update({name:this._translate.instant("integrating"), type: "spline"});
    this.chartRef.series[2].update({name:this._translate.instant("exercising"), type: "spline"});
    this.chartRef.series[1].update({name:this._translate.instant("nonAccess"), type: "spline"});
    this.chartRef.series[0].update({name:this._translate.instant("unconcerned"), type: "spline"});

    this.chartOptions = {
      title: {
        text: this._translate.instant("lineChartTitle"),
        margin: 32,
      },
      subtitle: {
        text: this._translate.instant("lineChartSubtitle"),
      },
      chart: {
        type: "spline",
      },
      xAxis: {
        labels: {
          format: "{value:%e-%m-%y}"
        },
        title: {
          text: this._translate.instant("obsDate"),
          align: "middle"
        },
        type: "datetime",
      },
      yAxis: {
        min: 0,
        title: {
          text: this._translate.instant("answers") +' (%)',
          align: 'high',
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'bottom',
        x: 8,
        y: -16,
        floating: false,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 0,
        shadow: false,
        symbolHeight: 20,
        symbolWidth: 20,
        symbolRadius: 0,
        itemMarginTop: 4,
        itemMarginBottom: 4,
        width: 160,
      },
      tooltip: {
        headerFormat: '<b>{point.x:%e-%m-%y}</b><br/>',
        pointFormat: '{series.name}: {point.y}%',
        valueDecimals: 0,
        split: true,
      },
      time: {
        timezone: "Europe/Zurich",
        useUTC: false
      }
    };

    this.chartRef?.redraw()
  }


  ngOnInit(): void {

    const allObservationDates = new Set();
    this.data.forEach(dataByLevel => {
      dataByLevel.forEach(data => {
        allObservationDates.add((data.x))
      });
    });
  
    this.data.forEach((dataByLevel, index) => {
      this.dataWithZeros.push([...this.data[index]])
      allObservationDates.forEach(date => {
        if(!dataByLevel.map(elem=>elem.x).includes(date)){
          this.dataWithZeros[index].push({x:date, y:0})
        }
      });
    });

    this.chartOptions = {
      ...this.chartOptions,
      title: {
        text: this._translate.instant("lineChartTitle"),
        margin: 32,
      },
      subtitle: {
        text: this._translate.instant("lineChartSubtitle"),
      },
      chart: {
        type: "spline",
      },
      xAxis: {
        labels: {
          format: "{value:%e-%m-%y}"
        },
        title: {
          text: this._translate.instant("obsDate"),
          align: "middle"
        },
        type: "datetime",
      },
      yAxis: {
        min: 0,
        title: {
          text: this._translate.instant("answers") +' (%)',
          align: 'high',
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'bottom',
        x: 8,
        y: -16,
        floating: false,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 0,
        shadow: false,
        symbolHeight: 20,
        symbolWidth: 20,
        symbolRadius: 0,
        itemMarginTop: 4,
        itemMarginBottom: 4,
        width: 160,
      },
      tooltip: {
        headerFormat: '<b>{point.x:%e-%m-%y}</b><br/>',
        pointFormat: '{series.name}: {point.y}%',
        valueDecimals: 0,
        split: true,
      },
      series: [
        {
          name: this._translate.instant('assimilated'),
          data: this.dataWithZeros[4].sort((a,b)=> a.x - b.x),
          type: 'spline',

        },
        {
          name: this._translate.instant('integrating'),
          data: this.dataWithZeros[3].sort((a,b)=> a.x - b.x),
          type: 'spline',
        },
        {
          name: this._translate.instant("exercising"),
          data: this.dataWithZeros[2].sort((a,b)=> a.x - b.x),
          type: 'spline',
        },
        {
          name: this._translate.instant('nonAccess'),
          data: this.dataWithZeros[1].sort((a,b)=> a.x - b.x),
          type: 'spline',
        },
        {
          name: this._translate.instant('unconcerned'),
          data: this.dataWithZeros[0].sort((a,b)=> a.x - b.x),
          type: 'spline',
        },
        /*       {
          name: "Sans Réponse",
          data: [0],
          type: 'spline',
        }, */
      ],
      time: {
        timezone: "Europe/Zurich",
        useUTC: false
      }
    };

    this._translate.onLangChange.subscribe(val => this.refreshChart())
  }
}
