@if (isLoading) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}
@if (!isLoading) {
  <ion-content #page>
    <ion-row style="position: relative; min-height: 100%">
      <ion-col style="margin-bottom: 80px" size="12" size-lg="10" offset-lg="1" size-xl="10" offset-xl="1">
        <app-header title="{{ 'viewObsTitle' | translate | capitalizeFirstLetter }}{{ obs.date | date: 'longDate' }}">
        </app-header>
        <a [routerLink]="['/', 'start','doc', recordId]" mat-button color="accent"
          style="font-size: 18px; color:#FD3F92;">
          <mat-icon>arrow_left</mat-icon>{{ ("backToRecord" | translate | capitalizeFirstLetter) }} {{ recordNumber }}
        </a>
        <h1 style="text-align: center;">
          {{ obs.signature ?? "anonyme" }}
        </h1>
        <ion-row>
          <ion-col style="text-align: center" size="2"> </ion-col>
          <ion-col size="8">
          <div [ngClass]="{
            matTitleInit: !isScrolled,
            matTitleScrolled: isScrolled
          }">
              @if (obs && !isScrolled) {
                <app-pdf [answers]="answers" [filteredQues]="filteredQues" isObs="true"
                  [min]="min | age | async" [max]="max | age | async" [signature]="obs.signature" [dateObs]="obs.date"
                [obsId]="obs.id" (isLoadingStarted)="onLoadingStarted()" (isLoadingEnded)="onLoadingEnded()"></app-pdf>
              }
            </div>
          </ion-col>
        </ion-row>
        <div class="sticky" style="z-index: 1000;">
          <p style="margin: 5px;">{{ "recordNumber" | translate | capitalizeFirstLetter }}{{ recordNumber }}</p>
          <p style="margin: 5px;">{{ "monthYearBirth" | translate | capitalizeFirstLetter }}: {{ birthdate | date:
          'MM/yyyy' }}</p>
          <p style="margin: 5px;">{{ "gender" | translate | capitalizeFirstLetter }}: {{ gender + "Gender" | translate }}
          </p>
          <app-square-toc [isModal]="true" [content]="page" [id]="2" [filteredCats]="filteredCats">
          </app-square-toc>
        </div>
        <ion-row>
          <ion-col size="12" size-lg="10" offset-lg="1" size-md="12">
            <div class="sticky">
              @if (true) {
                <app-filtres [answers]="answers" [ageAtObs]="ageAtObs" [min]="min" [obsId]="obs.id" [max]="max"
                  [isObsViewMode]="true" [selectedQuesRep]="selectedQuesRep" (filteredQuesEvent)="filteredQues = $event">
                </app-filtres>
              }
            </div>
            <mat-expansion-panel class="mat-elevation-z0" expanded style="margin-top: 8px; border-radius: 0px">
              <mat-expansion-panel-header>
                <mat-panel-title> Graphiques </mat-panel-title>
              </mat-expansion-panel-header>
              <div style="text-align: -webkit-center">
              <button [disabled]="isStart" mat-icon-button style="
                position: absolute;
                top: 50%;
                left: 0px;
                font-size: 25px;
                z-index: 2;
              " (click)="slidePrev()">
                  <mat-icon>arrow_back_ios</mat-icon>
                </button>
              <button [disabled]="isEnd" mat-icon-button style="
                position: absolute;
                top: 50%;
                right: 0px;
                font-size: 25px;
                z-index: 2;
              " (click)="slideNext()">
                  <mat-icon>arrow_forward_ios</mat-icon>
                </button>
                @if (answers) {
                  <ion-slides (ionSlideWillChange)="slideChanged()" #slides pager="true"
                    [options]="slideOpts">
                    <ion-slide>
                      <div id="spiderChart">
                        @if (answers) {
                          <app-spider-chart [data]="answers">
                          </app-spider-chart>
                        }
                      </div>
                    </ion-slide>
                    <ion-slide>
                      <div id="barChart">
                        @if (answers) {
                          <app-basic-bar style="width: 100%" [categories]="barChartCategories"
                          title="basicChartTitle" [chartValues]="barData"></app-basic-bar>
                        }
                      </div>
                    </ion-slide>
                    @for (cat of ['socialAffective','cognitive','language','motor','somatic']; track cat; let i = $index) {
                      <ion-slide
                        >
                        <div [id]="'subBarChart'+i">
                          @if (answers) {
                            <app-basic-bar style="width: 100%" [colors]="categoriesSettings[cat].colors"
                              [categories]="categoriesSettings[cat].legends"
                              title="catChartTitle"
                              [subCatParams]="{ domain: _translate.instant(cat), count: categoriesSettings[cat].subcats.length }"
                            [chartValues]="subCatBarData(cat)"></app-basic-bar>
                          }
                        </div>
                      </ion-slide>
                    }
                  </ion-slides>
                }
                <br />
              </div>
            </mat-expansion-panel>
            <hr>
              <app-table [filteredQues]="filteredQues" [isDraft]="false" [isDetailView]="false" [observation]="obs"
                (answersChanged)="updateAnswers($event)">
              </app-table>
            </ion-col>
          </ion-row>
        </ion-col>
        <app-footer></app-footer>
      </ion-row>
    </ion-content>
  }