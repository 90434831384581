<ion-content #content2>
  <div class="sticky">
    <ion-row>
      <ion-col style="text-align: center" size="2"> </ion-col>

      <ion-col size="8">
        <h1 style="text-align: center"></h1>
      </ion-col>

      <ion-col style="text-align: end" size="2">
        <ion-button mat-dialog-close fill="clear">
          <mat-icon>close</mat-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </div>

  @if (!data.isDossier) {
    <app-tuto-etab></app-tuto-etab>
  }
  @if (data.isDossier) {
    <app-tuto-dossier></app-tuto-dossier>
  }
</ion-content>
