import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  LoadingController,
} from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/core/core.state';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { selectRecordId } from 'src/app/core/record/record.selectors';
import { SharedService } from 'src/app/shared/services/shared.service';


@Component({
    templateUrl: './archive-record.component.html',
    styleUrls: ['./archive-record.component.scss'],
    standalone: false
})
export class ArchiveRecordComponent implements OnInit {
  recordId: string;
  constructor(
    public router: Router,
    private loadingCtrl: LoadingController,
    private _graphql: GraphqlService,
    private _translate: TranslateService,
    private _store: Store<AppState>,
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this._store.select(selectRecordId).pipe(take(1))
      .subscribe(id => this.recordId = id)
  }

  onArchive() {

    this.loadingCtrl
      .create({
        message: this._translate.instant("archivingRecord"),
      })
      .then((loadingEl) => {
        loadingEl.present();
        this._graphql.archiveRecord(this.recordId,)
          .pipe(take(1))
          .subscribe(
            {
              next: () => {
                this._graphql.emptyCache(),
                  this.router.navigate(['/start']);
                this.sharedService.makeToast(this._translate.instant("recordArchived"), '', 'success');
                loadingEl.dismiss();
              },
              error: (error) => {
                loadingEl.dismiss();
                this.sharedService.makeToast(this._translate.instant("errorOccured") + error, '', 'danger');
              }
            }
          )
      });
  }
}
