<ion-content class="has-header" overflow-scroll="true">
  <ion-row style="position: relative; min-height: 100%">
    <ion-col style="margin-bottom: 80px" size="12" size-lg="10" offset-lg="1" size-xl="10" offset-xl="1">
      <app-header title="params" backTo="etab" show="true"></app-header>
      @if (isLoading) {
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      }
      <br />

      @if (!isLoading) {
        <div class="container">
          <h1 class="card-header">{{ "myProfile" | translate | capitalizeFirstLetter }} <mat-icon class="only-icon"
          color="accent">person</mat-icon>
        </h1>
        <form [formGroup]="editUserform" (ngSubmit)="onEditUser()">
          <ion-row>
            <ion-col>
              <mat-form-field appearance="outline" color="accent" style="width: 100%">
                <mat-label>{{ "firstName" | translate | capitalizeFirstLetter }}</mat-label>
                <input matInput formControlName="firstname" required />
              </mat-form-field>
            </ion-col>
            <ion-col>
              <mat-form-field appearance="outline" color="accent" style="width: 100%">
                <mat-label>{{ "lastName" | translate | capitalizeFirstLetter }}</mat-label>
                <input matInput formControlName="lastname" required />
              </mat-form-field>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <mat-form-field appearance="outline" color="accent" style="width: 100%">
                <mat-label>{{ "newEmail" | translate | capitalizeFirstLetter }}</mat-label>
                <input matInput placeholder="E-mail" formControlName="email" required />
              </mat-form-field>
            </ion-col>
            <ion-col>
              <mat-form-field appearance="outline" color="accent" style="width: 100%">
                <mat-label>{{ "phone" | translate | capitalizeFirstLetter }}</mat-label>
                <input type="tel" matInput formControlName="phone" />
              </mat-form-field>
            </ion-col>
          </ion-row>
          <div style="display: flex; justify-content: center;">
            <button type="submit" mat-button
              [disabled]="!editUserform.valid || (userData.firstname == editUserform.value.firstname && userData.lastname == editUserform.value.lastname && userData.phone == editUserform.value.phone && userData.email == editUserform.value.email)"
              cdkFocusInitial>
              @if (!isEditUserLoading) {
                <span>{{ "save" | translate | capitalizeFirstLetter }}</span>
              }
              @if (isEditUserLoading) {
                <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'" color="accent" style="display: block;">
                </mat-progress-spinner>
              }
            </button>
          </div>
        </form>
        <div style="
            width: 33%;
            height: 1px;
            background-color: #bdbdbd;
            margin: 32px auto 32px auto;
          "></div>
        <h1 class="card-header">{{ "security" | translate | capitalizeFirstLetter }} <mat-icon class="only-icon"
        color="accent">shield</mat-icon>
      </h1>
      <app-edit-password></app-edit-password>
        <div style="
            width: 33%;
            height: 1px;
            background-color: #bdbdbd;
            margin: 32px auto 32px auto;
          "></div>
      <div class="flex-grid">
        @if (userData.subscription) {
          <app-card color="#EFEAE5" title="subscription" icon="credit_score"
            line1="{{ userData.subscription.cancelAtPeriodEnd ? translate.instant('subscriptionExpireAt') : translate.instant('subscriptionValidity')}}: {{ userData.subscription.currentPeriodEnd*1000 | date:'dd/MM/yyyy' }}"
            [button1]="userData.subscription.cancelAtPeriodEnd ? 'restoreCanceledSub' : 'cancelSubscription'"
          buttonIcon="cancel" (button1Clicked)="toggleCancelSub()"></app-card>
        }
      </div>
    </div>
  }
</ion-col>
<app-footer></app-footer>
</ion-row>
</ion-content>