import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-subscription-success',
    templateUrl: './subscription-success.component.html',
    styleUrls: ['./subscription-success.component.scss'],
    standalone: false
})
export class SubscriptionSuccessComponent implements OnInit {
  assetsPath = environment.assetspath;

  constructor() { }

  ngOnInit(): void {
  }

}
