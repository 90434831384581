import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { finalize, map, take } from 'rxjs/operators';
import { AppState } from 'src/app/core/core.state';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { selectRecordBirthdate, selectRecordId, selectValidatedObsIds } from 'src/app/core/record/record.selectors';
import { Obs } from 'src/app/shared/models/obs.model';
import { obsRep } from 'src/app/shared/models/obsRep.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { QuestionHistoryComponent } from '../../modals/question-history/question-history.component';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class TableComponent implements OnInit, OnDestroy {
  @Input() filteredQues: any;

  @Input() isDraft: boolean;

  @Input() observation: Obs;

  @Output() isSaving = new EventEmitter();
  @Output() answersChanged = new EventEmitter<{ [answerCode: string]: obsRep }>();

  answers: { [answerCode: string]: obsRep };
  previousAnswers: { [answerCode: string]: obsRep } = {};
  displayPreviousAnswers = false;
  isLoading = false;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  id: string;

  dataSource: any;

  age: number;

  columnsToDisplay = [
    'Age',
    'Question',
    'Réflexion',
    'Non-accès',
    "S'exerce",
    'Intègre',
    'Assimilé',
    'Options',
  ];

  constructor(
    private _graphql: GraphqlService,
    public dialog: MatDialog,
    private _sharedService: SharedService,
    private _translate: TranslateService,
    private _store: Store<AppState>,
    private toastCtrl: ToastController
  ) {
    this.displayPreviousAnswers = JSON.parse(localStorage.getItem('UMANWEB-DISPLAY_PREVIOUS_ANSWERS'));
  }


  ngOnInit(): void {
    this._store.select(selectRecordBirthdate)
      .pipe(take(1))
      .subscribe(birthdate => {
        this.age = this._sharedService.getAgeFromBirthdate(birthdate) - this._sharedService.getAgeFromBirthdate(this.observation.date)
      })

    this._store.select(selectValidatedObsIds)
      .pipe(
        take(1),
      ).subscribe(ids => {
        const previousId = ids.filter(id => id < this.observation.id).pop();
        if (previousId) {
          this._graphql.getObsAnswers(previousId)
            .pipe(
              take(1),
              map(({ data }: any) => data.observationAnswers.reduce((accu, val) => ({ ...accu, [val.answerCode]: { level: val.level, question: val.question } }), {})),
            ).subscribe(answers => {
              this.previousAnswers = answers;
            });
        }
      })
    this.isLoading = true;
    this._graphql.getObsAnswers(this.observation.id)
      .pipe(
        take(1),
        finalize(() => this.isLoading = false),
        map(({ data }: any) => data.observationAnswers.reduce((accu, val) => ({ ...accu, [val.answerCode]: { level: val.level, question: val.question } }), {})),
      ).subscribe(answers => {
        this.answers = answers;
        this.answersChanged.emit(answers);
      });
  }

  async makeToast(header: string, message: string, color: string) {
    const toast = await this.toastCtrl.create({
      color,
      header,
      message,
      duration: 1000,
      position: 'bottom',
    });
    toast.present();
  }

  setElementLevel(element, level, radio, ev) {
    ev.preventDefault();
    this.isSaving.emit(true);

    if (this.answers[element.answerCode]?.level === level) {
      delete this.answers[element.answerCode]
      radio.checked = false;
      this._graphql.deleteAnswer(this.observation.id, element.answerCode)
        .pipe(take(1))
        .subscribe(
          {
            next: () => {
              this.makeToast(this._translate.instant("answerDeleted"), '', 'warning');
              this.isSaving.emit(false);
            },
            error: (error) => {
              this.makeToast(
                this._translate.instant('deleteAnswerError'),
                'error',
                'danger'
              );
            }
          }
        )
    } else {
      radio.checked = true;
      this.answers[element.answerCode] = { ...this.answers[element.answerCode], level };

      this._graphql.updateOrCreateAnswer(this.observation.id, element.id, element.answerCode, level)
        .pipe(take(1))
        .subscribe(
          {
            next: () => this.isSaving.emit(false),
            error: (error) => {
              this.makeToast(
                this._translate.instant('saveAnswerError'),
                error,
                'danger'
              );
            }
          }
        );
    }
    this.answersChanged.emit(this.answers);
  }

  onHistoryView(answerCode: string) {
    this._store.select(selectRecordId)
      .pipe(take(1))
      .subscribe(recordId =>
        this.dialog.open(QuestionHistoryComponent, {
          width: '65%',
          data: {
            recordId,
            answerCode
          },
        })
      )
  }

  toggleDisplayPreviousAnswers(event) {
    this.displayPreviousAnswers = event.checked;
    localStorage.setItem('UMANWEB-DISPLAY_PREVIOUS_ANSWERS', event.checked);

  }

  ngOnDestroy() {
  }
}