import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalizeFirstLetter',
    standalone: false
})
export class CapitalizeFirstLetterPipe implements PipeTransform {
    transform(value: string): string {
        return value ? value[0].toUpperCase() + value.slice(1) : '';
    }
}