import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { finalize, take } from 'rxjs/operators';
import { AppState } from '../core/core.state';
import { GraphqlService } from '../core/graphql/graphql.service';
import { togglePasswordReset } from '../core/user/user.actions';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    standalone: false
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  isLoading = false;
  constructor(
    private toastCtrl: ToastController,
    private router: Router,
    private _graphql: GraphqlService,
    private _store: Store<AppState>,
    private _formBuilder: FormBuilder
  ) { }

  get f() { return this.changePasswordForm.value; }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const passwordConfirm = group.get('passwordConfirm').value;
    return password === passwordConfirm ? null : { notSame: true }
  }

  ngOnInit(): void {
    this.changePasswordForm = this._formBuilder.group({
      password: ['', [Validators.minLength(6), Validators.required]],
      passwordConfirm: ['', [Validators.required]],

    }, { validators: this.checkPasswords });

  }

  changePassword() {
    if (this.f.password == this.f.passwordConfirm) {
      this.isLoading = true
      this._graphql.resetPassword(this.f.password)

        .pipe(
          take(1),
          finalize(() => this.isLoading = false))
        .subscribe(
          {
            next: (_) => {
              this._store.dispatch(togglePasswordReset())
              this.router.navigateByUrl('/start')
            },
            error: async error => {
              const toast = await this.toastCtrl.create({
                color: 'danger',
                header: 'Impossible de valider le mot de passe',
                message: error,
                duration: 4000,
                position: 'bottom',
              });
              toast.present();
            }
          }
        )

    }

  }

}
