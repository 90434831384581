import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';

@Component({
    selector: 'app-create-user',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.scss'],
    standalone: false
})
export class CreateUserComponent implements OnInit {

  @Output() onCreateUser = new EventEmitter<any>();
  @Input() isLoading = false;
  userForm: FormGroup;
  constructor(
    private _formBuilder: FormBuilder,
    private _graphql: GraphqlService,
  ) { }

  get f() { return this.userForm.value; }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const passwordConfirm = group.get('passwordConfirm').value;
    return password === passwordConfirm ? null : { notSame: true }
  }

  ngOnInit(): void {
    this.userForm = this._formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      etab: ['', [Validators.required]],
      street: ['', [Validators.required, Validators.maxLength(100)]],
      number: ['', [Validators.required, Validators.maxLength(5)]],
      zip: ['', [Validators.required, Validators.maxLength(10)]],
      city: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ]
      ],
      password: ['', [Validators.minLength(6), Validators.required]],
      passwordConfirm: ['', [Validators.required]],
    }, { validators: this.checkPasswords });
  }

  async createUser() {
    this.onCreateUser.emit(this.f)
    }

}
