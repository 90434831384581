import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { LocalStorageService } from '../local-storage/local-storage.service';

import { GraphqlService } from '../graphql/graphql.service';
import { authLogin, authLogout } from './auth.actions';

export const AUTH_KEY = 'AUTH';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private router: Router,
    private graphql: GraphqlService,
  ) {
  }

  login = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogin),
        tap(() => {
          this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: true });
          this.router.navigateByUrl('/start');

        }
        )
      ),
    { dispatch: false }
  );

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogout),
        tap(() => {
          this.graphql.emptyCache();
          this.graphql.deleteToken().pipe()
            .subscribe(
              {
                next: (data: any) => {
                },
                error: (error) => {
                  console.error(error)
                }
              }
            )
          this.router.navigateByUrl('/login');
          this.localStorageService.setItem(AUTH_KEY, {
            isAuthenticated: false
          });
        })
      ),
    { dispatch: false }
  );
}
