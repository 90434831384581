<div style="padding: 24px;">
  <h1 style="text-align: center" mat-dialog-title>
    {{ (data.subscription.cancelAtPeriodEnd ? "restoreCanceledSub" : "cancelSubscription") | translate |
    capitalizeFirstLetter }}?
  </h1>
  <h2 style="text-align: center" mat-dialog-title>
    {{ "restoreCanceledSubDetails" | translate:{cancelDate:((1000*data.subscription.currentPeriodEnd) |
    date:'dd/MM/yyyy')} | capitalizeFirstLetter }}
  </h2>
  <div mat-dialog-actions style="display: flex; justify-content: center;">
    <button type="submit" mat-button (click)="onConfirm()" [mat-dialog-close]="true" cdkFocusInitial>
      @if (!isLoading) {
        <span>{{ "confirm" | translate | capitalizeFirstLetter }}</span>
      }
      @if (isLoading) {
        <mat-progress-spinner style="display: block; margin-left: auto; margin-right: auto;"
          [diameter]="24" [mode]="'indeterminate'" color="accent">
        </mat-progress-spinner>
      }
    </button>
    <button mat-button mat-dialog-close>{{ "cancel" | translate | capitalizeFirstLetter }}</button>
  </div>
</div>