<ion-card [style.border]="color ? '4px solid' + color : '4px solid'" [disabled]="isDisabled" class="card">
  @if (loading) {
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  }

  <div>
    <ion-card-title class="card-header">{{ title | translate | capitalizeFirstLetter }}</ion-card-title>
    @if (iconOnly) {
      <div  class="card-icon only-icon" >
        <mat-icon class="" color="accent">{{ icon }}</mat-icon>
      </div>
    }
    @if (!iconOnly) {
      <div>
        <mat-icon class="card-icon" color="accent" style="font-size: 42px; height: 42px; margin-top: 20px;">{{ icon }}</mat-icon>
        <div>
          @if (!isParagraph && line1) {
            <div class="info" style="color: #333333">
              <h3>{{ line1 }}</h3>
            </div>
          }
          @if (!isParagraph && line2) {
            <div class="info" style="color: #333333">
              <h3>{{ line2 }}</h3>
            </div>
          }
          @if (!isParagraph && line3) {
            <div class="info" style="color: #333333">
              <h3>{{ line3 }}</h3>
            </div>
          }
          @if (!isParagraph && line4) {
            <div class="info" style="color: #333333">
              <h3>{{ line4 }}</h3>
            </div>
          }
          @if (!isParagraph && line5) {
            <div class="info" style="color: #333333">
              <h3>{{ line5 }}</h3>
            </div>
          }
        </div>
        @if (isParagraph) {
          <div>
        <div class="info" style="
            color: #333333;
            margin: 15px;
            white-space: pre-line;
            text-align: center;
          ">
              <h3>{{ paragraph | translate | capitalizeFirstLetter}}</h3>
            </div>
          </div>
        }
      </div>
    }
  </div>
  <div class="card-buttons">
    @if (button1) {
      <button mat-flat-button color="accent" [disabled]="button1disabled" class="card-button"
        (click)="onB1Clicked()">
        {{ button1 | translate | capitalizeFirstLetter }}
        <mat-icon iconPositionEnd>{{ buttonIcon }}</mat-icon>
      </button>
    }
    @if (button2) {
      <button mat-flat-button color="accent" class="card-button" (click)="onB2Clicked()">
        {{ button2 | translate | capitalizeFirstLetter }}
        <mat-icon iconPositionEnd>arrow_forward</mat-icon>
      </button>
    }
  </div>
</ion-card>