import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Period {
  minValue: number;
  maxValue: number;
  viewValue: string;
}

interface PeriodGroup {
  disabled?: boolean;
  name: string;
  period: Period[];
}

@Component({
    templateUrl: './filtre.component.html',
    styleUrls: ['./filtre.component.scss'],
    standalone: false
})
export class FiltreComponent implements OnInit {
  periodGroups: PeriodGroup[] = [
    {
      name: 'A_p2_0_periodName',
      period: [
        { minValue: 0, maxValue: 36, viewValue: '0 - 3' },
        { minValue: 0, maxValue: 12, viewValue: '0 - 1' },
        { minValue: 12, maxValue: 24, viewValue: '1 - 2' },
        { minValue: 24, maxValue: 36, viewValue: '2 - 3' },
      ],
    },
    {
      name: 'A_p2_36_periodName',
      period: [
        { minValue: 36, maxValue: 96, viewValue: '3 - 8' },
        { minValue: 36, maxValue: 48, viewValue: '3 - 4' },
        { minValue: 48, maxValue: 60, viewValue: '4 - 5' },
        { minValue: 60, maxValue: 72, viewValue: '5 - 6' },
        { minValue: 72, maxValue: 84, viewValue: '6 - 7' },
        { minValue: 84, maxValue: 96, viewValue: '7 - 8' },
      ],
    },
    {
      name: 'A_p2_96_periodName',
      period: [{ minValue: 96, maxValue: 144, viewValue: '8 - 12' }],
    },
    {
      name: 'A_p2_144_periodName',
      period: [{ minValue: 144, maxValue: 216, viewValue: '12 - 18' }],
    },
    {
      name: 'A_p2_216_periodName',
      period: [{ minValue: 216, maxValue: 348, viewValue: '18 - 29' }],
    },
  ];

  selected = {
    lower: 40,
    upper: 60,
  };

  selectedPeriod: Period;

  lower: string;

  upper: string;

  maxLength: string;

  step = 1;

  isCustom = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FiltreComponent>
  ) { }

  ngOnInit(): void {
    this.setDefaultPeriod();

    this.disablePeriods();

    this.selected = {
      lower: this.data.min,
      upper: this.data.max,
    };

    this.isCustom = !this.periodGroups.some(
      group => group.period.some(
        p => p.minValue == this.selected.lower && p.maxValue == this.selected.upper
      )
    )

    this.maxLength = `29 ans`;

    if (
      (this.data.min > 0 && this.data.min < 12) ||
      (this.data.max > 0 && this.data.max < 12)
    ) {
      this.step = 1;
    } else if (
      (this.data.min > 11 && this.data.min < 46) ||
      (this.data.max > 11 && this.data.max < 46)
    ) {
      this.step = 3;
    } else if (
      (this.data.min > 45 && this.data.min < 144) ||
      (this.data.max > 45 && this.data.max < 144)
    ) {
      this.step = 6;
    } else if (this.data.min > 144 || this.data.min > 144) {
      this.step = 12;
    }
  }

  disablePeriods() {
    if (this.data.age < 36) {
      this.periodGroups.forEach((el) => {
        if (el.name !== 'Petite enfance') {
          el.disabled = true;
        }
      });
    } else if (this.data.age < 96) {
      this.periodGroups.forEach((el) => {
        if (el.name !== 'Petite enfance' && el.name !== 'Enfance') {
          el.disabled = true;
        }
      });
    } else if (this.data.age < 144) {
      this.periodGroups.forEach((el) => {
        if (el.name === 'Adolescence' || el.name === 'Jeune adulte') {
          el.disabled = true;
        }
      });
    } else if (this.data.age < 216) {
      this.periodGroups.forEach((el) => {
        if (el.name === 'Jeune adulte') {
          el.disabled = true;
        }
      });
    }
  }

  setDefaultPeriod() {
    if (this.data.age >= 0 && this.data.age < 36) {
      this.selectedPeriod = this.periodGroups[0].period[0];
      // } else if (this.data.age > 36 && this.data.age <= 48) {
      //   this.selectedPeriod = this.periodGroups[1].period[1];
      // } else if (this.data.age > 48 && this.data.age <= 60) {
      //   this.selectedPeriod = this.periodGroups[1].period[2];
      // } else if (this.data.age > 60 && this.data.age <= 72) {
      //   this.selectedPeriod = this.periodGroups[1].period[3];
      // } else if (this.data.age > 72 && this.data.age <= 84) {
      //   this.selectedPeriod = this.periodGroups[1].period[4];
    } else if (this.data.age >= 36 && this.data.age < 96) {
      this.selectedPeriod = this.periodGroups[1].period[0];
    } else if (this.data.age >= 96 && this.data.age < 144) {
      this.selectedPeriod = this.periodGroups[2].period[0];
    } else if (this.data.age >= 144 && this.data.age < 216) {
      this.selectedPeriod = this.periodGroups[3].period[0];
    } else if (this.data.age >= 216 && this.data.age < 348) {
      this.selectedPeriod = this.periodGroups[4].period[0];
    }
  }

  init() {
    this.setDefaultPeriod();
    this.selected = {
      lower: this.data.min,
      upper: this.data.max,
    };
  }

  onChange(ev) {
    this.selected = {
      lower: Math.min(ev.detail.value.lower, this.data.age),
      upper: ev.detail.value.upper
    };

    if (
      (ev.detail.value.lower >= 0 && ev.detail.value.lower < 12) ||
      (ev.detail.value.upper >= 0 && ev.detail.value.upper < 12)
    ) {
      this.step = 1;
    } else if (
      (ev.detail.value.lower >= 12 && ev.detail.value.lower < 46) ||
      (ev.detail.value.upper >= 12 && ev.detail.value.upper < 46)
    ) {
      this.step = 3;
    } else if (
      (ev.detail.value.lower >= 46 && ev.detail.value.lower < 144) ||
      (ev.detail.value.upper >= 46 && ev.detail.value.upper < 144)
    ) {
      this.step = 6;
    } else if (ev.detail.value.lower >= 144 || ev.detail.value.upper > 144) {
      this.step = 12;
    }

  }

  cancel() {
    this.dialogRef.close({ data: false });
  }

  confirm() {
    if (!this.isCustom) {
      this.dialogRef.close({
        data: true,
        min: this.selectedPeriod.minValue,
        max: this.selectedPeriod.maxValue,
      });
    } else {
      this.dialogRef.close({
        data: true,
        min: this.selected.lower,
        max: this.selected.upper,
      });
    }
  }
}
