@if (isLoading) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}
@if (!isLoading) {
  <ion-content>
    <ion-row style="position: relative; min-height: 100%">
      <ion-col style="margin-bottom: 80px" size="12" size-lg="10" offset-lg="1" size-xl="10" offset-xl="1">
        <app-header [title]="_translate.instant('synthesis')" show="true" backTo="etab"></app-header>
        <div #header class="sticky">
          <a [routerLink]="['/', 'start','doc', recordId]" mat-button color="accent"
            style="font-size: 18px; color:#FD3F92;">
            <mat-icon>arrow_left</mat-icon>{{ ("backToRecord" | translate | capitalizeFirstLetter) }} {{ recordNumber }}
          </a>
          <ion-row>
            <ion-col style="text-align: center" size="2"> </ion-col>
            <ion-col size="8">
            <div [ngClass]="{
                matTitleInit: !isScrolled,
                matTitleScrolled: isScrolled
              }">
                @if (!isScrolled) {
                  <app-pdf-synthese (isLoadingStarted)="onLoadingStarted()"
                  (isLoadingEnded)="onLoadingEnded()"></app-pdf-synthese>
                }
              </div>
            </ion-col>
          </ion-row>
        </div>
        <ion-row>
          <ion-col size="12" size-lg="10" offset-lg="1" size-xl="10" offset-xl="1">
            <div style="display: flex; flex-direction: row; justify-content: center;" id="highchart1">
              <app-assimilation-chart [data]="graphData.assimilationData"></app-assimilation-chart>
              <hr />
            </div>
            @if (earlyLateAnswers) {
              <div>
                @for (obs of earlyLateAnswers | keyvalue; track obs) {
                  <div>
                    <h2>{{ "viewObsTitle" | translate | capitalizeFirstLetter }}{{ obs.key | date:'dd/MM/yyyy'}}</h2>
                    @if (obs.value.early.length > 0) {
                      <div>
                        <h3 style='color: #55c40c'>{{ "learningEarly" | translate | capitalizeFirstLetter }}:</h3>
                        <ol>
                          @for (elem of obs.value.early; track elem) {
                            <li>{{ elem | translate | capitalizeFirstLetter }}</li>
                          }
                        </ol>
                      </div>
                    }
                    @if (obs.value.late.length > 0) {
                      <div>
                        <h3 style='color: #b51c1c'>{{ "learningLate" | translate | capitalizeFirstLetter }}:</h3>
                        <ol>
                          @for (elem of obs.value.late; track elem) {
                            <li>{{ elem | translate | capitalizeFirstLetter }}</li>
                          }
                        </ol>
                      </div>
                    }
                  </div>
                }
              </div>
            }
            <br />
          </ion-col>
        </ion-row>
      </ion-col>
      <app-footer></app-footer>
    </ion-row>
  </ion-content>
}